import axios from "axios";
import Links from "../constants/Links";

const Nationality = {
    /**
     * fetch all countries
     * @returns 
     */
    countries: async () => {
        return axios.get(Links.nationality.countries)
    },

    /**
     * fetch states base selected Country
     * @param {*} country 
     * @returns 
     */
    states: async (country) => {
        return axios.get(Links.nationality.states + country + '/states')
    },

    /**
     * fetch all countries
     * @returns 
     */
    getCountries: async () => {
        return axios.get(Links.nationality.getCountries)
    },

    getStatesAdmin: async (country) => {
        return axios.get(Links.nationality.getStates + country + '?a=1')
    },

    /**
     * Provide the country id to fetch the state respectively 
     * @param {*} country 
     * @returns 
     */
    getStates: async (country) => {
        return axios.get(Links.nationality.getStates + country)
    },

    /**
     * provide the state id to fetch its lga respectively
     * @param {string} state 
     * @returns 
     */
    getLgas: async (state) => {
        return axios.get(Links.nationality.getLgas + state )
    },

    getLgasAsAdmin: async (state) => {
        return axios.get(Links.nationality.getLgas + state + '?a=1' )
    },

    getTribes: async () => {
        return axios.get(Links.nationality.getTribes)
    },

    getTribesAsAdmin: async () => {
        return axios.get(Links.nationality.getTribes + '?a=1' )
    },

    createCountry: async (data) => {
        return axios.post(Links.nationality.createCountry, data)
    },

    createState: async (data) => {
        return axios.post(Links.nationality.createState, data)
    },

    createLga: async (data) => {
        return axios.post(Links.nationality.createLga, data)
    },

    createTribe: async (data) => {
        return axios.post(Links.nationality.createTribe, data)
    },

    updateCountry: async (id, data) => {
        return axios.post(Links.nationality.updateCountry + '/' + id, data)
    },

    updateState: async (id, data) => {
        return axios.post(Links.nationality.updateState + '/' + id, data)
    },

    updateLga: async (id, data) => {
        return axios.post(Links.nationality.updateLga + '/' + id, data)
    },

    updateTribe: async (id, data) => {
        return axios.post(Links.nationality.updateTribe + '/' + id, data)
    },

    deleteCountry: async (id) => {
        return axios.put(Links.nationality.deleteCountry + '/' + id)
    },

    deleteState: async (id) => {
        return axios.put(Links.nationality.deleteState + '/' + id)
    },

    deleteLga: async (id) => {
        return axios.put(Links.nationality.deleteLga + '/' + id)
    },
}

export default Nationality