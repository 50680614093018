<template>
 <div class="parent d-flex flex-column justify-center">
   <div class="mx-auto">
     <v-img :class="{'rounded rounded-circle': true, 'image-nsuk': nsuk, 'image-lafia': !nsuk}" :src="image" width="150px" aspect-ratio="1"></v-img>
   </div>
   <div class="mx-auto display-1 my-4 white--text">{{ user != null ? (user.user === undefined ? user.name : user.user.name) : "School Portal" }}</div>
   <div class="mx-auto my-2">
      <v-text-field @keyup.enter="unlock" autofocus :readonly="loading" color="primary" type="password" placeholder="Password" append-icon="mdi-lock" dark v-model="pass.password" outlined rounded dense class="text-white"></v-text-field>
     <div class="text-center error--text" v-if="error">{{'Incorrect password'}}</div>
   </div>
   <div class="text-center my-3">
     <v-btn :disabled="user == null" :loading="loading" color="primary" outlined rounded @click.stop="unlock"><v-icon color="white">mdi-arrow-right</v-icon></v-btn>
     <message-box :show="messageShow" :message="message" :type="messageType" v-on:close-message="messageShow = !messageShow" style="z-index: 999999999"></message-box>
   </div>
 </div>
</template>

<script>
import Defaults from "@/shared/Defaults";
import MessageBox from "@ui/components/MessageBox";
import Api from "@/main/api";
import ErrorResolver from "@/shared/ErrorResolver";
import Apptypes from "@/shared/constants/apptypes";

export default {
  name: "LockScreen",
  components: {MessageBox},
  props: ['user'],
  data: () => ({
    pass: {
      username: '',
      password: '',
    },
    messageType: '',
    message: '',
    messageShow: false,
    loading: false,
    errorCount: 0,
    error: false,
    nsuk: false
  }),

  computed: {
    image: function(){
      if(this.user == null){
        return 'https://picsum.photos/seed/picsum/200/300'
      } else{
        return Defaults.api + '/profile-picture/fetch?user=' + (this.user.user === undefined ? this.user.userId : this.user.user.userId);
      }
    }
  },

  methods: {
    unlock(){
      if(this.pass.password != null && this.pass.password !== ''){
        this.loading = true
        this.pass.username = this.user.user === undefined ? this.user.userId : this.user.user.userId;
        Api.auth.unlock(this.pass)
        .then(res => {
          if(res.data.success){
            this.errorCount = 0
            this.error = false
            this.$emit("reset")
            // unlock
          } else{ // error
            this.error = true
            this.errorCount++
            if(this.errorCount >= 3){
              this.$emit("logout")
            }
          }
        }).catch(err => ErrorResolver.checkErrors(this, err))
            .finally(() => this.loading = false)
      }
    }
  },

  watch: {
    user: function(newVal){
      if(newVal != null){
        this.pass.username = newVal.user === undefined ? newVal.userId : newVal.user.userId;
      }
    }
  },

  created(){
    this.nsuk = Apptypes.NSUK === Apptypes.CURRENT
  }
}
</script>

<style scoped>
.parent {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  background-color: #000000ea;
  z-index: 99999999;
}

.v-text-field--outlined >>> fieldset {
  border-color: var(--v-primary-base);
}

.text-white .v-text-field__slot input {
  color: white !important;
}

.image-nsuk{
  border: solid #016b4b 4px
}

.image-lafia{
  border: solid #FF9931 4px
}
</style>
