import Links from "../constants/Links";
import axios from "axios";

/**
 * All apis for payment platforms settings
 * @type {{all: (function(): Promise<AxiosResponse<any>>), update: (function(*): Promise<AxiosResponse<any>>), getActive: (function(): Promise<AxiosResponse<any>>), toggleActive: (function(*): Promise<AxiosResponse<any>>)}}
 */
const PaymentPlatforms = {
    /**
     * Returns all payment platforms defined in the system
     * @returns {Promise<AxiosResponse<*>>}
     */
    all: async () => {
        return axios.get(Links.paymentPlatforms.all)
    },

    /**
     * Update a payment method
     * @param data the payment method object to update. The data object must have the following properties:
     * name, privateKey, publicKey, verificationLink, paymentLink, invoiceUrl, enableSplit(true or false),
     * splitUrl(Optional. only required if enable split is true),
     * payerIdUrl(this is required when using CBS. leave a note for the admin)
     * serviceType and active(true or false),
     * @returns {Promise<AxiosResponse<*>>}
     */
    update: async (data) => {
        return axios.post(Links.paymentPlatforms.update, data)
    },

    /**
     * Toggles the active state of a payment gateway
     * @param name the name of the payment method
     * @returns {Promise<AxiosResponse<*>>}
     */
    toggleActive: async (name) => {
        return axios.put(Links.paymentPlatforms.toggleActive + '/' + name)
    },

    /**
     * gets the active payment method
     * @returns {Promise<AxiosResponse<*>>}
     */
    getActive: async () => {
        return axios.get(Links.paymentPlatforms.getActive)
    },

    /**
     * gets payment method by name
     * @returns {Promise<AxiosResponse<*>>}
     */
    find: async (name) => {
        return axios.get(Links.paymentPlatforms.find + "?name=" + name)
    }
}

export default PaymentPlatforms
