import axios from "axios";
import Links from "../constants/Links";

const Results = {
    create: async (data) => {
        return axios.post(Links.results.create, data)
    },

    updateSingle: async (data) => {
        return axios.put(Links.results.updateSingleResult, data)
    },

    deleteResult: async(id) => {
        return axios.put(Links.results.deleteResult + "/" + id)
    },

    fetchAllForCourseForSemester: async (sessionId, semesterId, courseId, page, itemsPerPage, programmeId) => {
        return axios.get(Links.results.getAllForCourseForSemester + "?sessionId=" + sessionId + '&semesterId=' + semesterId + '&courseId=' + courseId + '&page=' + page + '&size=' + itemsPerPage + (programmeId ? ("&programme="+programmeId) : ""))
    },

    downloadAllForCourseForSemester: async (sessionId, semesterId, courseId) => {
        return axios.get(Links.results.downloadAllForCourseForSemester + sessionId + '/' + semesterId + '/' + courseId, {
            responseType: 'blob'
        })
    },

    downloadAcademicReport: async (programme, semesterId, level) => {
        return axios.get(Links.results.downloadReport + "/" + programme + '/' + semesterId + '/' + level, {
            responseType: 'blob'
        })
    },

    getReportForCourse: async (sessionId, semesterId, courseId) => {
        return axios.get(Links.results.getReportFor + sessionId + '/' + semesterId + '/' + courseId)
    },

    uploadResult: async (data, sessionId, semesterId, courseId) => {
        return axios.post(Links.results.uploadResult + sessionId + '/' + semesterId + '/' + courseId, data)
    },

    moderate: async (data, sessionId, semesterId, courseId) => {
        return axios.put(Links.results.moderate + sessionId + '/' + semesterId + '/' + courseId, data)
    },

    submitCourse: async (data) => {
        return axios.post(Links.results.submitCourse, data);
    },

    reportForLevelProgrammeDepartment: async (programme, semester, level) => {
        return axios.get(Links.results.getReportForProgrammeLevelDep + '?programme='+ programme+ '&level=' + level + '&semester=' + semester )
    },

    reportForLevelProgrammeFaculty: async (programme, semester, level) => {
        return axios.get(Links.results.getReportForProgrammeLevelFac + '?programme='+ programme+ '&level=' + level + '&semester=' + semester )
    },

    reportForLevelProgrammeSubCommittee: async (programme, semester, level) => {
        return axios.get(Links.results.getReportForProgrammeLevelSub + '?programme='+ programme+ '&level=' + level + '&semester=' + semester )
    },

    reportForLevelProgrammeSenate: async (programme, semester, level) => {
        return axios.get(Links.results.getReportForProgrammeLevelSen + '?programme='+ programme+ '&level=' + level + '&semester=' + semester )
    },

    reportForLevelDepartment: async (department, session, semester, level, senate) => {
        return axios.get(Links.results.getReportForDepartmentLevel + department + '/' + level + '/' + session + '/' + semester + (senate ? '?senate=1' : ''))
    },

    reportForDepartment: async (department, session, semester, senate) => {
        return axios.get(Links.results.getReportForDepartment + department + '/' + session + '/' + semester + (senate ? '?senate=1' : ''))
    },

    reportForFaculty: async (faculty, session, semester) => {
        return axios.get(Links.results.getReportForFaculty + faculty + '/' + session + '/' + semester )
    },


    // approvals
    approvalCourseAtDept: async (courseId, sessionId, semester) => {
        return axios.put(Links.results.approvalCourseAtDept + sessionId + '/' + semester + '/' + courseId )
    },
    disApprovalCourseAtDept: async (courseId, sessionId, semester) => {
        return axios.put(Links.results.disApprovalCourseAtDept + sessionId + '/' + semester + '/' + courseId )
    },

    // faculty
    approvalResultsFaculty: async (programme, level, semester) => {
        return axios.put(Links.results.approvalResultsFaculty + '?programme=' + programme + '&semester=' + semester + '&level=' + level )
    },
    disApprovalResultsFaculty: async (programme, level, semester) => {
        return axios.put(Links.results.disApprovalResultsFaculty + '?programme=' + programme + '&semester=' + semester + '&level=' + level )
    },

    // subcommittee
    approvalResultsSubCommittee: async (programme, level, semester) => {
        return axios.put(Links.results.approvalResultsSubCommittee + '?programme=' + programme + '&semester=' + semester + '&level=' + level )
    },
    disApprovalResultsSubCommittee: async (programme, level, semester) => {
        return axios.put(Links.results.disapprovalResultsSubCommittee + '?programme=' + programme + '&semester=' + semester + '&level=' + level )
    },

    // senate
    approvalResultsSenate: async (programme, semester, level) => {
        return axios.put(Links.results.approvalResultsSenate + '?programme=' + programme + '&level=' + level + '&semester=' + semester )
    },
    disApprovalResultsSenate: async (programme, semester, level) => {
        return axios.put(Links.results.disApprovalResultsSenate + '?programme=' + programme + '&level=' + level + '&semester=' + semester )
    },

    forStudent: async (student, session, semester) => {
        return axios.get(Links.results.forStudent + "?student=" + student + '&session=' + session + '&semester=' + semester);
    },

    uploadScoreSheetAdmin: async (data, semesterId) => {
        return axios.post(Links.results.uploadSheetAdmin + '/' + semesterId , data)
    },

    approveAllResults: async (sessionId, semesterId) => {
        return axios.post(Links.results.approveAllResults, {
            session: sessionId,
            semester: semesterId
        })
    },

    deleteAllResults(semester, programme, course, deleteRegistrations){
        return axios.delete(Links.results.deleteAllResult + "?programmeId=" + programme + "&semesterId=" + semester + "&courseId=" + course + "&deregister=" + deleteRegistrations)
    }

}

export default Results
