 import axios from "axios";
import Api from "@/main/api";
import router from "../../routes"; 
import LoginUtil from "@/login/LoginUtil";
import ErrorResolver from "@/shared/ErrorResolver";
import Roles from "@/shared/Roles";

const state = {
  user: null,
  toasts: [],
};


const actions = {
  fetchCurrentUser: ({ commit }) => {
    const _user = axios.get("get-current-user")
      .then((res) => {
        commit("setCurrentUser", res.data);
      })


      return _user;
       
  },

  signin: async ({ commit }, formData) => {
    if (
        formData.password === undefined ||
        formData.password === "" ||
        formData.username === undefined
      )
      return; 


    const res = await Api.auth.login(formData)

    if(res.data) {
      LoginUtil.setAccessToken(res.data.jwt);
      router.push('status'); 
    }

    if(Roles.contains(res.data.roles, Roles.Admin)) {
      window.location.href = '/main'; 
    }
    
    return res; 
  
  },

  updatePassword() {

  },

  logout: () => {
    LoginUtil.resetAccessToken();
    //LoginUtil.resetRefreshToken();
  },
};

const getters = {
  getUser: (state) => state.user,
};

const mutations = {
  setCurrentUser: (state, data) => {
    state.user = data.user;
  },

  addToast(state, toast) {
    state.toasts.push(toast);
  }, 

  clearToast(state, title){
    const index = state.toasts.findIndex((toast) => toast.title === title); 
    state.toasts.splice(index, 1); 
  }
};

export default {
  state,
  actions,
  getters,
  mutations,
};
