import axios from "axios";
import Links from "../constants/Links";

const Transcript = {
  /**
   * @description - fetch the transcript settings
   * @returns
   */
  getSetting: async () => {
    return axios.get(Links.transcript.getSetting);
  },

  /**
   * @description fetch all modes of delivery
   * @returns
   */
  getModeofDeliveries: async () => {
    return axios.get(Links.transcript.getmodeDelivery);
  },

  /**
   * @description fetch transcript delivery statuses
   * @returns
   */
  getDeliveryStatus: async () => {
    return axios.get(Links.transcript.deliveryStatus);
  },

  /**
   * @description update transcript fee
   */
  updateTranscriptFee: async (data) => {
    return axios.post(Links.transcript.updateFee, data);
  },

  /**
   * @description - fetch all transcript delivery payment
   * @returns
   */
  getModeofDeliveryPayment: async (deliverymode) => {
    return axios.get(
      Links.transcript.getModeofDeliveryPayment +
        "?mode_of_delivery=" +
        deliverymode
    );
  },

  /**
   * @description updoate transcript mode of payment
   * @param {*} data
   * @returns
   */
  updateModeAndPayment: async (data) => {
    return axios.post(Links.transcript.updateModePayment, data);
  },

  /**
   * @description fetch all countries and their prices
   */

  getCountryandPayment: async (country) => {
    return axios.get(
      Links.transcript.countryAndPayment + "?country=" + country
    );
  },

  /**
   * @description update country and their amount
   * @param {*} data
   * @returns
   */
  updateCountryandPayment: async (data) => {
    return axios.post(Links.transcript.updateCountryAndPayment, data);
  },

  getStateAndPayment: async (state) => {
    return axios.get(Links.transcript.getStateAndPayment + "?state=" + state);
  },

  /**
   * @description update state and payment
   * @param {*} data
   * @returns
   */
  updateStateAndPayment: async (data) => {
    return axios.post(Links.transcript.updateStateAndPayment, data);
  },

  /**
   * @description wave session based on each session id
   * @returns {Boolean} true or false as the case may be
   */
  waveSession: async (data) => {
    return axios.post(Links.transcript.waveSession, data);
  },

  /**
   * update the required details such as password, email etc
   * @notadmin
   */

  updateDetails: async (data) => {
    return axios.post(Links.transcript.updateDetails, data);
  },


  /**
   * retrive single transcript record base on userId provided
   * @param {String} id 
   * @returns 
   */
  getTranscriptById: async (id) => {
    return axios.get(Links.transcript.getTranscript, id); 
  },


  /**
   * retrive all transcript record base on the userId provided
   * @param {String} userId 
   * @returns 
   */
  getAlltranscriptRecord: async (userId) => {
    return axios.get(Links.transcript.allTranscript + "?userId=" + userId)
  }, 

  /**
   * send transcript request with the provided data passed
   * @param {string} userId 
   * @param {Array<string>} data
   * @returns 
  */
  transcriptRequet: async (userId, data) => {
    return axios.post(Links.transcript.makeRequest, { userId, data }); 
  }, 

  /**
   * edit Address 
   * @param {string} id 
   * @param {object} data 
   * @returns 
   */
  editAddress: async (id, data) => {
    return axios.put(Links.transcript.editAddress + '/' + id, data);
  }
};

export default Transcript
