import axios from "axios";
import Links from "../constants/Links";

const Requirements = {
    getSsceRequirements: async () => {
        return axios.get(Links.requirements.getSsceRequirements)
    },

    updateSSCEGrades: async (data) => {
        return axios.post(Links.requirements.updateSSCEGrades, data)
    },

    updateSSCEExamTypes: async (data) => {
        return axios.post(Links.requirements.updateSSCEExamTypes, data)
    },

    updateSSCEOrganization: async (data) => {
        return axios.post(Links.requirements.updateSSCEOrganization, data)
    },

    getQualificationDetails: async () => {
        return axios.get(Links.requirements.getQualificationDetails)
    },

    updateQualificationGrade: async (data) => {
        return axios.post(Links.requirements.updateQualificationGrade, data)
    },

    updateApplicantQualification: async (data) => {
        return axios.post(Links.requirements.updateApplicantQualification, data)
    },

    getInstitutionTypes: async () => {
        return axios.get(Links.requirements.getInstitutionTypes)
    },

    updateInstitutionTypes: async (data) => {
        return axios.post(Links.requirements.updateInstitutionTypes, data)
    },

    getSubjects: async () => {
        return axios.get(Links.requirements.getSubjects)
    },

    updateSubject: async (data) => {
        return axios.post(Links.requirements.updateSubject, data)
    },

    updateUserUTMEDetails: async(data) => {
        return axios.post(Links.requirements.updateUserUTMEDetails, data)
    },

    updateUserSSCEDetails: async(data) => {
        return axios.post(Links.requirements.updateUserSSCEDetails, data, {
            headers: { 'Content-Type': 'multipart/form-data' },
        })
    },
}

export default Requirements