import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter);


const routes = [
    { path: '/', component: () => import("../pages/Registration.vue") },
    { path: '/application', component: () => import("../pages/Application.vue")},
    { path: '/dashboard', component: () => import("../pages/Dashboard.vue")},
    { path: '/change-program', component: () => import("../pages/Program.vue")},
    { path: '/data-correction', component: () => import("../pages/Data-Correction.vue") },

];



export default new VueRouter({
    routes
})
