import axios from "axios";
import Links from "../constants/Links";

const Semester = {
    update: async (semester, data) => {
        return axios.put(Links.semester.update + semester, data)
    },

    activate: async (semester) => {
        return axios.put(Links.semester.activate + semester)
    },

    deactivate: async (semester) => {
        return axios.put(Links.semester.deactivate + semester)
    },
}

export default Semester