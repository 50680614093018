import axios from "axios";
import Links from "../constants/Links";

const Hospital = {

    /**
     * @description Create a new hospital
     * @param data  the payload of the request. contains the following properties: name, code
     * @returns The created hospital
     */
    create: async (data) => {
        return axios.post(Links.hospital.create, data)
    },

    /**
     * @description Updates an hospital
     * @param id The id of the hospital to update
     * @param data  the payload of the request. contains the following properties: name, code
     * @returns The created hospital
     */
    update: async (id, data) => {
        return axios.put(Links.hospital.update + '/' + id, data)
    },

    /**
     * @description Deletes a hospital object
     * @param id the id of the hospital object to delete
     * @returns empty data
     */
    delete: async (id) => {
        return axios.delete(Links.hospital.delete + '/' + id)
    },

    /**
     * @description Gets all hospital data
     * @returns hospitals
     */
    getAll: async () => {
        return axios.get(Links.hospital.all)
    }
}

export default Hospital; 