import axios from "axios";
import Links from "../constants/Links";

const Session = {
    all: async () => {
        return axios.get(Links.session.all);
    },

    /**
     * @description Gets all sessions for a programme type
     * @param programmeTypeId(number)
     * @returns {Promise<AxiosResponse<any>>}
     */
    
    allFor: async (programmeTypeId) => {
        return axios.get(Links.session.allForProgrammeType + '?programme_type=' + programmeTypeId);
    },

    /**
     * @description Returns sessions and levels
     * @deprecated to be removed. use allFor
     * @param prog programme time id
     * @returns {Promise<AxiosResponse<any>>}
     */
    allForProgrammeType: async (prog) => {
        return axios.get(Links.session.allForProg + prog);
    },

    create: async(data) => {
        return axios.post(Links.session.create, data);
    },

    update: async(session, data) => {
        return axios.put(Links.session.update + session, data);
    },

    current: async(programmeTypeId) => {
        return axios.get(Links.session.current + programmeTypeId);
    },

    activate: async (data) => {
        return axios.put(Links.session.activate + data.id)
    },

    deactivate: async (data) => {
        return axios.put(Links.session.deactivate + data.id)
    },

    getRegisteredSessionsForStudent: async (studentId) => {
        return axios.get(Links.session.getRegisteredSessionsForStudent + '?user_id=' + studentId)
    },

    getFullPaidSessionsForStudent: async (studentId) => {
        return axios.get(Links.session.getFullPaidSessionsForStudent + '?user_id=' + studentId)
    },

    getSessionsForPayment: async (studentId) => {
        return axios.get(Links.session.getSessionsForPayment + '?user_id=' + studentId);
    },
}

export default Session