import axios from "axios";
import Links from "../constants/Links";

const Admission= {
    stats: async (faculty, session) => {
        return axios.get(Links.admission.stats + faculty + '/' + session)
    },

    enable: async (session) => {
        return axios.put(Links.admission.enable + session)
    },

    disable: async (session) => {
        return axios.put(Links.admission.
            disable + session)
    },

    getApplicationTypes: async () => {
        return axios.get(Links.admission.getApplicationTypes)
    },

    getApplicationTypesFor: async (programmeTypeId) => {
        return axios.get(Links.admission.getApplicationTypesFor + '?programme_type=' + programmeTypeId)
    },

    createApplicationType: async (data) => {
        return axios.post(Links.admission.createApplicationType, data)
    },

    updateApplicationType: async (id, data) => {
        return axios.post(Links.admission.updateApplicationType + id, data)
    },

    getAdmissions: async (programmeType) => {
        return axios.get(Links.admission.getAdmissions + '?prog_type=' + programmeType)
    },

    createAdmission: async (data) => {
        return axios.post(Links.admission.createAdmission, data)
    },

    updateAdmission: async (id, data) => {
        return axios.post(Links.admission.updateAdmission + '/' + id , data)
    },

    getActiveAdmissions: async () => {
        return axios.get(Links.admission.getActiveAdmissions)
    },

    /**
     * 
     * @param {*} applicantId 
     * @returns 
     */
    getLetter: async (applicantId) => {
        return axios.get(Links.admission.getLetter + '?q=' + applicantId, {
            responseType: 'blob'
        })
    }
}

export default Admission