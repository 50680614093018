import axios from "axios";
import Links from "../constants/Links";

const ProgrammeType = {
    create: async (data) => {
        return axios.post(Links.programmeTypes.create, data)
    },


    update: async (id, data) => {
        return axios.put(Links.programmeTypes.update + '/' + id, data)
    },

    all: async () => {
        return axios.get(Links.programmeTypes.all)
    },

    /**
     * @description Updates payment settings for a programme type
     * @param id the ID of the programme type
     * @param data and object containing: feePaymentMode(1 for Session and 2 for semester), 
     * partPayment(true or false), 
     * firstPaymentPercentage (number between 0.0 - 100.0), serviceCode(string)
     * @returns the updated programme type
     */
    updatePaymentDetails: async (id, data) => {
        return axios.put(Links.programmeTypes.updatePaymentDetails + '/' + id, data)
    },

    /**
     * @description Returns programme types for the currently logged in user
     */
    allForCurrentUser: async () => {
        return axios.get(Links.programmeTypes.forCurrenUser)
    }
}

export default ProgrammeType