export default {

    // values here supposed to start from 0, but instead, starts from one because of javascript's definition of 0 as undefined. 1 is subtracted from results at the backend
    // if at all any logic similar to backedn logic wou dbe done, then 1 has tobe subtracted from all values
    ACCOUNT_CREATED: {
        text: 'ACCOUNT_CREATED',
        value: 1,
    },

    MAIL_SENT: {
        text: 'MAIL_SENT',
        value: 2
    },

    APPLICATION_PENDING: {
        text: 'APPLICATION_PENDING',
        value: 3,
    },

    APPLICATION_COMPLETE: {
        text: 'APPLICATION_COMPLETE',
        value: 4,
    },

    SCREENING_PAYMENT_PENDING: {
        text: 'SCREENING_PAYMENT_PENDING',
        value: 5,
    },

    SCREENING_REQUIREMENTS_PENDING: {
        text: 'SCREENING_REQUIREMENTS_PENDING',
        value: 6
    },

    SCREENING_PENDING: {
        text: 'SCREENING_PENDING',
        value: 7
    },

    SCREENING_NOT_SUCCESSFUL: {
        text: 'SCREENING_NOT_SUCCESSFUL',
        value: 8
    },

    SCREENING_SUCCESSFUL: {
        text: 'SCREENING_SUCCESSFUL',
        value: 9
    },

    ADMISSION_OFFERED: {
        text: 'ADMISSION_OFFERED',
        value: 10
    },

    ADMISSION_DECLINED: {
        text: 'ADMISSION_DECLINED',
        value: 11,
    },

    ADMISSION_ACCEPTED: {
        text: 'ADMISSION_ACCEPTED',
        value: 12
    },


    ADMISSION_REJECTED: {
        text: 'ADMISSION_REJECTED',
        value: 13
    },
    
    PROGRAMME_CHANGE: {
        text: "PROGRAMME_CHANGE", 
        value: 14
    },

    CLEARED: {
        text: 'CLEARED',
        value: 15,
    },

    STUDENT: {
        text: 'STUDENT',
        value: 16,
    },

    COMPLETED: {
        text: 'COMPLETED'
    },

    SPILLED: {
        text: 'SPILLED'
    },

    WITHDREW: {
        text: 'WITHDREW'
    },

    SUSPENDED: {
        text: 'SUSPENDED'
    },

    PROBATED: {
        text: 'PROBATED'
    },

    RUSTICATED: {
        text: 'RUSTICATED'
    },

    DEFERRED: {
        text: 'DEFERRED'
    },

    // values here supposed to start from 0, but instead, starts from one because of javascript's definition of 0 as undefined. 1 is subtracted from results at the backend
    asArrays: function () {
        return [
            {
                text: 'ACCOUNT_CREATED',
                value: 1,
            },

            {
                text: 'MAIL_SENT',
                value: 2
            },

            {
                text: 'APPLICATION_PENDING',
                value: 3,
            },

            {
                text: 'APPLICATION_COMPLETE',
                value: 4,
            },

            {
                text: 'SCREENING_PAYMENT_PENDING',
                value: 5,
            },

            {
                text: 'SCREENING_REQUIREMENTS_PENDING',
                value: 6
            },

            {
                text: 'SCREENING_PENDING',
                value: 7
            },

            {
                text: 'SCREENING_NOT_SUCCESSFUL',
                value: 8
            },

            {
                text: 'SCREENING_SUCCESSFUL',
                value: 9
            },

            {
                text: 'ADMISSION_OFFERED',
                value: 10
            },

            {
                text: 'ADMISSION_DECLINED',
                value: 11,
            },
            {
                text: 'ADMISSION_ACCEPTED',
                value: 12,
            },
            {
                text: 'ADMISSION_REJECTED',
                value: 13,
            },
            {
                text: 'PROGRAMME_CHANGE',
                value: 14,
            },
            {
                text: 'CLEARED',
                value: 15
            },
            {
                text: 'STUDENT',
                value: 16,
            },
        ]
    }

}