<template>
  <v-card id="cardx" :class="{'rounded-lg': home && shadowed}" :tile="tile" :color="color" :rounded="rounded" :hover="hover" :flat="flat" :loading="loading" :elevation="Number.parseInt(elevation)" outlined :max-height="maxheight" :max-width="maxwidth" style="padding-bottom: 20px" class="">
    <slot />
  </v-card>
</template>

<script>
import AppTypes from "@/shared/constants/apptypes";

export default {
  name: "XCard",
  data: () => ({
    shadowed: false
  }),
  props: {
    loading: {
      type: Boolean,
      default: false
    },

    elevation: {
      type: [Number, String],
      default: 0
    },

    maxwidth: {
      type: [String, Number],
      default: '100%'
    },

    maxheight: {
      type: [String, Number],
      default: '100%'
    },

    flat: {
      type: Boolean,
      default: true
    },

    outlined: {
      type: Boolean,
      defauly: true
    },
    rounded: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: ''
    },
    tile: {
      type: Boolean,
      default: true
    },
    hover: {
      type: Boolean,
      default: true
    },

    home: {
      type: Boolean,
      default: false
    }
  },

  watch: {
    home: function(val){
      if(val){
        this.shadowed = AppTypes.CURRENT !== AppTypes.NSUK
      }
    }
  },

  created() {
    this.shadowed = AppTypes.CURRENT !== AppTypes.NSUK
  }
}
</script>

<style scoped>

</style>
