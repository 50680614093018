<template>
    <div class="logo">
        <router-link :to="dashboardPath" replace>
            <v-avatar size="50">
<!--                <v-img contain src="../Lafia/assets/lafia.png" alt="Federal University Lafia" />-->
            </v-avatar>
        </router-link>
    </div>
</template>


<script>

export default {
    props: {
        source: {
            type: String,
            default: ''
        },

        name: {
            type: String,
            default: 'FUK'
        },
    },


    computed: {
        dashboardPath() {
            return '/dashboard'
        }
    }
}
</script>


<style lang="scss" scoped>
.logo {
    padding: 10px;
    display: grid;

    img {
        object-fit: cover;
        object-position: left center;
    }
}
</style>
