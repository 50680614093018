import axios from 'axios'
import Links from '../constants/Links'

const AuditTrail = {
    /**
     * @description Fetch and returns audit trails result
     * @param page the page to fetch
     * @param size size of data to return
     * @param date if specified, only activities that happens on that faithful day will be fetched. 
     * format is year-month-day
     * @returns Paginated result of audit trails
     */
    getAll: async (page, size, date) => {
        return axios.get(Links.auditTrail.all + '?page=' + page + '&size=' + size + (date ? '&date=' + date : ''))
    }
}

export default AuditTrail