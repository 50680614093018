<template>
  <v-select v-bind="$attrs" :outlined="true" rounded dense flat filled solo :value="value" @input="$emit('input', $event)">
    <slot />
  </v-select>
</template>

<script>
export default {
  name: "XSelect",
  props: ['value']
}
</script>

<style scoped>

</style>
