import axios from "axios";
import Links from "@/shared/constants/Links";

const UtmeCombination = {
    create: async (data) => {
        return axios.post(Links.utmeCombination.create, data)
    },

    update: async (id, data) => {
        return axios.put(Links.utmeCombination.update + '/' + id, data)
    },

    delete: async (id) => {
        return axios.delete(Links.utmeCombination.delete + '/' + id)
    },

    fetch: async (department) => {
        return axios.get(Links.utmeCombination.fetch + '?department=' + department)
    }
}

export default UtmeCombination