import axios from "axios";
import Links from "../constants/Links";

/**
 * @description Apis for global settings
 * @type {{fetch: (function(): Promise<AxiosResponse<any>>), update: (function(*): Promise<AxiosResponse<any>>), updateEmailConfig: (function(*): Promise<AxiosResponse<any>>), updateLogo: (function(*): Promise<AxiosResponse<any>>)}}
 */
const Global = {
    /**
     * @description Returns global settings object which contains school global informtaion and email configuration
     * @returns {Promise<AxiosResponse<*>>}
     */

    fetch: async () => {
        return axios.get(Links.global.fetch)
    },


    update: async (data) => {
        return axios.post(Links.global.update, data)
    },


    updateLogo: async (data) => {
        return axios.post(Links.global.updateLogo, data)
    },

    /**
     * @description Updates email config
     * @param data the object containing the properties to be updated. the properties are : emailPassword, emailUsername, emailHost, emailPort
     * @returns {Promise<AxiosResponse<any>>}
     */
    updateEmailConfig: async (data) => {
        return axios.post(Links.global.updateEmailConfig, data)
    },

    /**
     * @description Updates global options
     * @param data an object containing the following properties: 
     * examSubCommitteeEnabled (boolean), facultyTitle(String), departmentTitle(String)
     * @returns {Promise<AxiosResponse<any>>}
     */
    updateOptions: async (data) => {
        return axios.post(Links.global.updateOptions, data)
    },


    /**
     * @description - enable global notification
     * @param data - enable --> yes : disable --> no
     * 
    */
    enableGlobalNotification: async (data) => {
        return axios.post(Links.global.enableNotification, data); 
    }
}

export default Global