import axios from "axios";
import Links from "../constants/Links";

const SchoolFees = {
    /**
     * @description Creates a fee item title
     * @param data object containing the following properties: title
     * @returns the created object
     */
    createTitle: async(data) => {
        return axios.post(Links.schoolFees.createTitle, data)
    },


    /**
     * @description Updates a fee item title
     * @param id ID of the title to update
     * @param data object containing the following properties: title
     * @returns the updated object
     */
     updateTitle: async(id, data) => {
        return axios.put(Links.schoolFees.updateTitle + '/' + id, data)
    },

    /**
     * @description Fetches all titles
     * @returns List of fee titles
     */
    allTitle: async () => {
        return axios.get(Links.schoolFees.fetchTitles)
    },

    /**
     * @description Makes an item invincible from invoice items
     * @param id the id of the item
     * @returns the modified fee item
     */
    toggleHidden: async(id) => {
        return axios.put(Links.schoolFees.toggleHidden + '/' + id)
    },

    /**
     * @description Makes an item a late payment item
     * @param id the id of the item
     * @returns the modified fee item
     */
    toggleLatePayment: async(id) => {
        return axios.put(Links.schoolFees.toggleLatePayment + '/' + id)
    },

    /**
     * @description Creates a new fee item
     * @param data  an object containing the following:
     * titleId: fee item title ID,
     * sessionId: ID of the session ,
     * stateType: 1 for indigene, 2 for Non indigene and 3 for both. the user selects the option,
     * amount: Amount for the item,
     * fee: 1 for school fee. it should be an option. for now there exists only one option,
     * faculty: ID of faculty to support
     * department: ID of department to support,
     * programme: ID of programme this item supports,
     * level: ID of level this item supports,
     * modeOfEntryId: ID of the mode of entry this item should support. select nothing for all modes of entry,
     * latePaymentItem: True of False. marks whether this item should be treated as a late payment item
     * @returns the newly created item
     */
    // createNewFeeItem: async (data) => {
    //     return axios.post(Links.schoolFees.createItem, data);
    // },


    /**
     * @description Creates a new fee item
     * @param data  an object containing the following:
     * titleId: fee item title ID,
     * sessionId: ID of the session,
     * stateType: 1 for indigene, 2 for Non indigene and 3 for both. the user selects the option,
     * amount: Amount for the item,
     * feeType: 1 for school fee. it should be an option. for now there exists only one option,
     * faculty: ID of faculty to support,
     * department: ID of department to support,
     * programme: ID of programme this item supports,
     * level: ID of level this item supports,
     * studentCategory: Category of students. 1 for applicants and 2 for returning students and null for both
     * latePaymentItem: True of False. marks whether this item should be treated as a late payment item
     * @returns the newly created item
     */
     createNewFeeItem: async (data) => {
        return axios.post(Links.schoolFees.createItem, data);
    },

    /**
     * @description Updates a new fee item
     * @id ID of the fee item to update
     * @param data  an object containing the following:
     * titleId: fee item title ID,
     * sessionId: ID of the session ,
     * stateType: 1 for indigene, 2 for Non indigene and 3 for both. the user selects the option,
     * amount: Amount for the item,
     * feeType: 1 for school fee. it should be an option. for now there exists only one option,
     * faculty: ID of faculty to support,
     * department: ID of department to support,
     * programme: ID of programme this item supports,
     * level: ID of level this item supports,
     * studentCategory: Category of students. 1 for applicants and 2 for returning students and null for both
     * latePaymentItem: True of False. marks whether this item should be treated as a late payment item
     * @returns the newly updated item
     */
     updateFeeItem: async (id, data) => {
        return axios.put(Links.schoolFees.updateItem + '/' + id, data);
    },


    /**
     * @description Deletes a fee item
     * @param itemId  id of the fee item to deletee
     * @returns
     */
    deleteItem: async (itemId) => {
        return axios.delete(Links.schoolFees.deleteItem + itemId)
    },

    /**
     * @description Fetches fee for session and/or other params
     * @param session the session id. this is required
     * @param faculty the faculty id. optional
     * @param department department id: optional
     * @param programme progtamme id. optional
     * @param level level id. optional
     * @param category category of students. 1 for applicants, 2 for returning students and null for both, optional
     * @param indigene 1 for indigene and 2 for non indigene. null is for both. optional
     * @returns
     */
    fetchAllItems: async (session, faculty, department, programme, level, category, indigene) => {
        return axios.get(
            Links.schoolFees.fetchItems + '?session=' + session + (faculty ? '&faculty=' + faculty : '')
                + (department ? '&department=' + department : '')
                + (programme ? '&programme=' + programme : '')
                + (level ? '&level=' + level : '')
                + (category ? '&category=' + category : '')
                + (indigene ? '&indigene=' + indigene : '')
        )
    },

    /**
     * @deprecated
     * @param {*} programmeTypeid
     * @param {*} sessionId
     * @returns
     */
    allProgrammeType: async (programmeTypeid, sessionId) => {
        return axios.get(Links.schoolFees.allProgrammeType + programmeTypeid + '/' + sessionId)
    },

    /**
     * @deprecated
     * @param {*} programmeTypeid
     * @param {*} sessionId
     * @param {*} levelId
     * @returns
     */
    allLevel: async (programmeTypeid, sessionId, levelId) => {
        return axios.get(Links.schoolFees.allProgrammeType + programmeTypeid + '/' + sessionId + '/' + levelId)
    },

    getFeeFor: async (session, student) => {
        return axios.get(Links.schoolFees.getFeeFor + '?session=' + session + '&user=' + student)
    },

    initializePayment: async (data) => {
        return axios.post(Links.schoolFees.initializePayment, data)
    },

    refreshTransaction: async (paymentId) => {
        return axios.put(Links.schoolFees.refreshTransaction + paymentId);
    },

    verifyTransaction: async (transactionId) => {
        return axios.put(Links.schoolFees.verifyTransaction + transactionId);
    },

    copyItems: async (session) => {
        return axios.put(Links.schoolFees.copyItems + session)
    },

    reportForFaculty: async (programme_type, faculty, session) => {
        return axios.get(Links.schoolFees.reportForFaculty + "?programme_type="+programme_type + "&faculty=" + faculty + "&session=" + session)
    },

    // delete: async (itemId) => {
    //     return axios.put(Links.schoolFees.deleteItem + itemId)
    // },

    invoice: async (invoice) => {
        return axios.get(Links.schoolFees.invoice + '?invoice=' + invoice, {
            responseType: "blob"
        })
    },

    /**
     * @description View reports
     * @param reqParams  an object containing the following:
     * session,
     * payment(min 1 and max 6),
     * startDate(yyyy-mm-dd) and endDate(yyyy-mm-dd)
     * session and payment are required while the others are optional
     * @param page the page to fetch
     * @param size the size of the page
     * @returns
     */
    report: async (reqParams, page, size) => {
        let params = '?session=' + reqParams.session
        params = params + '&payment=' + reqParams.payment
        if(reqParams.startDate){
            params = params + '&start_date=' + reqParams.startDate
        }

        if(reqParams.endDate){
            params = params + '&end_date=' + reqParams.endDate
        }

        return axios.get(Links.schoolFees.report + params + '&page=' + page + '&size=' + size)
    },

    /**
     * @description Donwloads reports  in xlsx format
     * @param reqParams  an object containing the following: session, p
     * payment(min 1 and max 6), startDate(yyyy-mm-dd) and endDate(yyyy-mm-dd)
     * session and payment are required while the others are optional
     * @returns
     */
     downloadReport: async (reqParams) => {
        let params = '?session=' + reqParams.session
        params = params + '&payment=' + reqParams.payment
        if(reqParams.startDate){
            params = params + '&start_date=' + reqParams.startDate
        }

        if(reqParams.endDate){
            params = params + '&end_date=' + reqParams.endDate
        }

        return axios.get(Links.schoolFees.downloadReport + params, {
            responseType: 'blob'
        })
    },

    clearBatch: async (data) => {
        return axios.post(Links.schoolFees.clearBatch, data, {
            headers: { 'Content-Type': 'multipart/form-data' },
            timeout: 1000 * 60 * 5
        });
    },


    /**
     * @description - send the standalone name
     * @retuns true
     */

    fetchStandAloneTitle: async()  => {
        return axios.get(Links.schoolFees.standAloneTitles);
    },

    /**
     * @description Fetches fee for session and/or other params
     * @param session the session id. this is required
     * @param faculty the faculty id. optional
     * @param department department id: optional
     * @param programme progtamme id. optional
     * @param level level id. optional
     * @param category category of students. 1 for applicants, 2 for returning students and null for both, optional
     * @param indigene 1 for indigene and 2 for non indigene. null is for both. optional
     * @returns
     */
    fetchAllStandaloneItems: async (session, faculty, department, programme, level, category, indigene) => {
        return axios.get(
            Links.schoolFees.fetchStandeloneItems + '?session=' + session + (faculty ? '&faculty=' + faculty : '')
            + (department ? '&department=' + department : '')
            + (programme ? '&programme=' + programme : '')
            + (level ? '&level=' + level : '')
            + (category ? '&category=' + category : '')
            + (indigene ? '&indigene=' + indigene : '')
        )
    },

    /**
     * @description - create a new standalone fee
     * @param {*} data
     * @returns
     */
    createStandalone(data) {
        return axios.post(Links.schoolFees.createStandalone, data)
    },

    /**
     * @description - update the new standalone fee
     * @param {*} id
     * @param {*} data
     * @returns
     */
    updateStandalone(id, data) {
        return axios.put(Links.schoolFees.updateStandalone + '/' + id, data)
    },

    /**
     * @description - delete the standalone fee base on id
     * @param {*} id
     * @returns
     */
    deleteStandalone(id){
        return axios.delete(Links.schoolFees.deleteStandalone + '/' + id)
    },

    standaloneForUser(session, user){
        return axios.get(Links.schoolFees.standaloneForStudent + "?session=" + session + "&user=" + user)
    }
}

export default SchoolFees
