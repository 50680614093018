import axios from "axios";
import Links from "../constants/Links";

const StudentInformation = {
    getInformation: async (id) => {
        return axios.get(Links.studentInformation.getInformation + '?id=' + id)
    },

    updateInformation: async (id, data) => {
        return axios.put(Links.studentInformation.updateInformation + '?id=' + id, data)
    },

    getIDCardDetails: async (id) => {
        return axios.get(Links.studentInformation.getIDCardDetails + '?id=' + id)
    },

    download: async (studentId) => {
        return axios.get(Links.studentInformation.download + '?student=' + studentId, {
            responseType: 'blob'
        })
    },

    /**
     * @description Downloads ID cards fro students
     * @param reqParams an object containing the following as ID's:session,  faculty, department, programme, level. all except session are optional
     * @returns a pdf document
     */
    cards: async (reqParams) => {
        let params = '?session=' + reqParams.session

        if(reqParams.faculty){
            params = params + '&faculty=' + reqParams.faculty
        }

        if(reqParams.department){
            params = params + '&department=' + reqParams.department
        }

        if(reqParams.programme){
            params = params + '&programme=' + reqParams.programme
        }

        if(reqParams.level){
            params = params + '&level=' + reqParams.level
        }

        if(reqParams.paymentDate){
            params = params + '&date=' + reqParams.paymentDate
        }
        return axios.get(Links.studentInformation.cards + params, {
            responseType: 'blob'
        })
    },

    /**
     * @description Downloads ID for a single student
     * @param studentId the ID of the student to download ID card for
     * @returns a pdf document
     */
    cardSingle: async (studentId) => {
        return axios.get(Links.studentInformation.cardSingle + '?student=' + studentId, {
            responseType: 'blob'
        })
    },
}

export default StudentInformation
