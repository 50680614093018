import axios from "axios";
import Links from "../constants/Links";

const Biometric = {
    enrollFingerPrint: async (userId, data) => {
        return axios.post(Links.biometric.enrollFingerPrint + userId, data)
    },
    checkEnrollment: async (userId) => {
        return axios.get(Links.biometric.checkEnrollment + userId)
    },
    verify: async (userId, data) => {
        return axios.post(Links.biometric.verify + userId, data)
    }
}

export default Biometric