import axios from "axios";
import Links from "../constants/Links";

const Students = {
  create: async (data) => {
    return axios.post(Links.permanentStudents.create, data);
  },

  createTemp: async (data) => {
    return axios.post(Links.temporalStudents.create, data);
  },

  updateTemp: async (id, data) => {
    return axios.post(Links.temporalStudents.update + id, data);
  },

  uploadTemp: async (data, params) => {
    return axios.post(
      Links.temporalStudents.upload + "session/" + params.session,
      data,
      {
        headers: { "Content-Type": "multipart/form-data" },
        timeout: 1000 * 60 * 5,
      }
    );
  },

  uploadPerm: async (data) => {
    return axios.post(Links.permanentStudents.upload, data, {
      headers: { "Content-Type": "multipart/form-data" },
      timeout: 1000 * 60 * 5,
    });
  },

  allPermanentFor: async (departmentId, level, page, itemsPerPage) => {
    return axios.get(
      Links.permanentStudents.all +
        departmentId +
        "?levelId=" +
        level +
        "&page=" +
        page +
        "&size=" +
        itemsPerPage
    );
  },

  allStudents: async (reqParams, page, itemsPerPage) => {
    let params = "?session=" + reqParams.session;
    if (reqParams.level) {
      params = params + "&level=" + reqParams.level;
    }
    if (reqParams.faculty) {
      params = params + "&faculty=" + reqParams.faculty;
    }
    if (reqParams.department) {
      params = params + "&department=" + reqParams.department;
    }
    if (reqParams.programme) {
      params = params + "&programme=" + reqParams.programme;
    }
    if (reqParams.country) {
      params = params + "&country=" + reqParams.country;
    }
    if (reqParams.state) {
      params = params + "&state=" + reqParams.state;
    }
    if (reqParams.lga) {
      params = params + "&lga=" + reqParams.lga;
    }
    if (reqParams.gender) {
      params = params + "&gender=" + reqParams.gender;
    }
    return axios.get(
      Links.permanentStudents.allStudents +
        params +
        "&page=" +
        page +
        "&size=" +
        itemsPerPage
    );
  },

  downloadRecordSheet: async (reqParams) => {
    let params = "?session=" + reqParams.session;
    if (reqParams.level) {
      params = params + "&level=" + reqParams.level;
    }
    if (reqParams.faculty) {
      params = params + "&faculty=" + reqParams.faculty;
    }
    if (reqParams.department) {
      params = params + "&department=" + reqParams.department;
    }
    if (reqParams.programme) {
      params = params + "&programme=" + reqParams.programme;
    }
    if (reqParams.country) {
      params = params + "&country=" + reqParams.country;
    }
    if (reqParams.state) {
      params = params + "&state=" + reqParams.state;
    }
    if (reqParams.lga) {
      params = params + "&lga=" + reqParams.lga;
    }
    if (reqParams.gender) {
      params = params + "&gender=" + reqParams.gender;
    }

    params = params + "&paid=" + reqParams.paid;

    return axios.get(Links.permanentStudents.downloadReport + params, {
      responseType: "blob",
    });
  },

  /**
   *
   * @param query search query  (userid or institutional email)
   * @param programmeTypeId programmeTypeId
   * @returns the query database on the query specified
   */
  search: async (query, programmeTypeId) => {
    return axios.get(
      Links.permanentStudents.search +
        "?q=" +
        query +
        "&programme_type=" +
        programmeTypeId
    );
  },

  /**
   * @para query user id
   * @returns the query database on the query specified
   */
  find: async (query) => {
    return axios.get(Links.permanentStudents.find + "?q=" + query);
  },

  migrateSingle: async (id) => {
    return axios.put(Links.temporalStudents.migrateSingle + id);
  }, // not available

  migrateAll: async (dep, session, progType) => {
    return axios.put(
      Links.temporalStudents.migrateAllDep +
        dep +
        "/" +
        session +
        "/" +
        progType
    );
  }, // not available

  offerAdmission: async (data) => {
    return axios.post(Links.temporalStudents.offerAdmission, data);
  },

  registerCourse: async (data) => {
    return axios.post(Links.permanentStudents.registerCourse, data);
  },

  unRegisterCourse: async (data) => {
    return axios.post(Links.permanentStudents.unRegisterCourse, data);
  },

  getRegisteredCoursesForSemester: async (student, session, semester) => {
    return axios.get(
      Links.permanentStudents.getRegisterCourses +
        "?student=" +
        student +
        "&session=" +
        session +
        "&semester=" +
        semester
    );
  },

  suspend: async (data) => {
    return axios.post(Links.permanentStudents.suspend, data);
  },

  /**
   * @description extend student deferment
   * @param sessionid pass the sessionid
   * @param data - the data contains studentId and reason
   */
  extend: async (data, session) => {
    return axios.post(Links.permanentStudents.extend + session, data);
  },

  cancelSuspension: async (suspendId) => {
    return axios.put(Links.permanentStudents.cancelSuspension + suspendId);
  },

  /**
   * @description to cancel deferment id
   * @param {string} defermentId - the defermentid to be passed
   * @param {string} sessionId - the sessionId to be passed
   * @returns
   */
  cancelDeferment: async (deferId, sessionId) => {
    return axios.put(
      Links.permanentStudents.cancelDeferment + "/" + deferId + "/" + sessionId
    );
  },

  /**
   * fetch all deferred student
   * @param {number} page
   * @param {number} size
   * @returns
   */
  allDeferredStudent: async (page, size) => {
    return axios.get(
      Links.permanentStudents.allDeferredStudent +
        "?page=" +
        page +
        "&size=" +
        size
    );
  },

  /**
   * return axios.get(Links.lecturers.search + "?query=" + query)
   */
  /**
   * search for deferred student using the following
   * @param {string} query userId(matric) or student name
   * @returns result
   */
  searchForDeferredStudent: async (query) => {
    return axios.get(Links.permanentStudents.searchDeferStudent + "?query=" + query);
  },

  suspendedAndExtended: async (programmeType, departmentId) => {
    return axios.get(
      Links.permanentStudents.suspendedAndExtended +
        programmeType +
        "/department/" +
        departmentId
    );
  },

  suspendedAndExtendedAll: async (programmeType) => {
    return axios.get(
      Links.permanentStudents.suspendedAndExtended + programmeType
    );
  },

  fetchByProgrammeAndLevel: async (programmeId, levelId, page, total) => {
    return axios.get(
      Links.permanentStudents.fetchByProgrammeAndLevel +
        programmeId +
        "/" +
        levelId +
        "?page=" +
        page +
        "&size=" +
        total
    );
  },

  upgradeLevel: async (data) => {
    return axios.post(Links.permanentStudents.upgradeLevel, data);
  },

  statsForProgramme: async (programme, level) => {
    return axios.get(
      Links.permanentStudents.statsForProgramme +
        "?programme=" +
        programme +
        "&level=" +
        level
    );
  },

  searchWithBiometricStatus: async (query, programmeType) => {
    return axios.get(
      Links.permanentStudents.searchWithBiometricStatus +
        "?q=" +
        query +
        "&programme_type=" +
        programmeType
    );
  },

  downgradeLevel: async (userId, maintainPromotion) => {
    return axios.put(Links.permanentStudents.downgradeLevel, {
      id: userId,
      maintainPromotion,
    });
  },

  updateModeOfEntry: async (data) => {
    return axios.post(Links.permanentStudents.updateModeOfEntry, data)
  },

  generateMatricNumber: async (matric) => {
    return axios.put(Links.permanentStudents.generateMatric, {
      id: matric,
    });
  },

  updateProgramme: async (req) => {
    return axios.post(Links.permanentStudents.updateProgramme, req);
  },

  /**
   * Get the stats of the app
   * No of student, rusticated etc
   * @returns 
  */
  stats: async (session) => {
    return axios.get(
      Links.permanentStudents.stats + 
      "?session=" +
      session
    )
  }
};

export default Students
