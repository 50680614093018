<template>
    <div class="my-2" >

            <v-row class="mt-1 mx-1">
                <v-col md="3" class="d-flex ml-auto">
                    <x-select placeholder="Session" item-text="name" v-model="selectedSession" return-object outlined rounded dense  :items="sessions"/>
                    <!-- <x-select class="ml-md-3" outlined rounded dense/> -->
                </v-col>
            </v-row>

            <!-- CONTENT-->
            <div class="mx-2">
                <v-sheet outlined rounded class="basic--text">
                    <div v-if="selectedSession === null" class="mx-auto text-center">

                        <div >
                            <v-simple-table dense>
                                <tbody>
                                    <tr>
                                        <th>S/N</th>
                                        <th class="text-center">Title</th>
                                        <th class="text-right">Amount</th>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                        </div>
                        <div class="d-flex mx-auto">
                            <h2 class="d-flex mx-auto font-weight-light my-md-10 text-center">No Session Provided</h2>
                        </div>
                    </div>

                    <div v-else>
                        <div class="text-right mx-md-2 basic--text">Total: {{ toatalAmount }}</div>
                        <v-divider />
                        <v-simple-table dense>
                            <tbody>
                                <tr>
                                    <th>S/N</th>
                                    <th class="text-center">Title</th>
                                    <th class="text-right">Amount</th>
                                </tr>

                                <tr v-for="(item, index) in itemsWithSerialNo" :key="index">
                                    <td>{{ item.sno }}</td>
                                    <td>{{ item.itemTitle.title }}</td>
                                    <td class="text-right">{{ item.amount }}</td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                    </div>
                </v-sheet>
            </div>



        <loading-dialog :show="loading"></loading-dialog>
        <message-box :show="messageShow" :message="message" :type="messageType" v-on:close-message="messageShow = !messageShow"></message-box>
    </div>
</template>


<script>
import LoadingDialog from "ui/lafia/components/LoadingDialog.vue";
import MessageBox from "@ui/components/MessageBox";
import { mapGetters } from "vuex";
import Api from "@/main/api";
import ErrorResolver from "shared/ErrorResolver";
import XSelect from 'ui/widgets/XSelect.vue';

export default {
    name: "Payment",
    components: {
        XSelect,
        LoadingDialog,
        MessageBox
    },

    data: () => ({
        messageType: '',
        message: '' ,
        messageShow: false,
        loading: false,
        items: [],
        sessions: [],
        selectedSession: null,
        feePayment: null,
        parts: [],
        payPart: false,
        paymentGateway: null,
        paymentConfig: null,
        user: null,
    }),


    methods: {
        fetchSessiosnForPayment() {
            if(this.getUser != null){
                this.loading = true;
                Api.session.getFullPaidSessionsForStudent(this.getUser.userId)
                    .then((res) => this.sessions = res.data)
                    .catch((err) => ErrorResolver.checkErrors(this, err))
                    .finally(() => this.loading = false)
            }

        },


        getPayment() {
            if(this.getUser != null && this.selectedSession){
                this.loading = true;
                this.feePayment = null;
                this.items = [];
                this.parts = [];
                Api.schoolFees.getFeeFor(this.selectedSession.id, this.getUser.userId)
                    .then((res) => {
                        this.feePayment = res.data.feePayment;
                        if(res.data.student != null){
                            this.user = res.data.student;
                        }


                        this.items = res.data.feeItems;
                        if(res.data.feePayment != null){
                            this.parts = this.feePayment.partPayments;
                        }
                    })
                    .catch((error) => ErrorResolver.checkErrors(this, error))
                    .finally(() => this.loading = false)
            }
        }
    },

    watch: {
        selectedSession: function(val){
            if(val != null){
                this.getPayment();
            }
        },

        getUser: function() {
            this.fetchSessiosnForPayment();
        }
    },

    computed: {
        ...mapGetters(['getUser']),

        itemsWithSerialNo: function() {
            return this.items.map((d, index) => ({ ...d, sno: index + 1}))
        },

        amountPaid: function() {
            if(this.parts.length < 1){
                return 0;
            }

            return this.parts.map((val) => val.amountPaid).reduce((previousValue, currentValue) => previousValue + currentValue, 0);
        },

        toatalAmount: function() {
            return this.items.map((value) => value.amount).reduce((prev, curr) => prev + curr, 0)
        }
    },

    created() {
        this.fetchSessiosnForPayment();
    }
}
</script>
