import axios from "axios";
import Links from "../constants/Links";

const Faculty = {
    create: async (data) => {
        return axios.post(Links.faculties.create, data)
    },

    update: async (data) => {
        return axios.put(Links.faculties.update, data);
    },

    find: async (id) => {
        return axios.get(Links.faculties.find + id)
    },

    all: async () => {
        return axios.get(Links.faculties.all)
    },

    allWithDeans: async () => {
        return axios.get(Links.faculties.allWithDeans)
    },

    forCurrentUser: async (user) => {
        return axios.get(Links.faculties.forCurrentUser + '?staff=' + user)
    }
}

export default Faculty