<template>
    <v-card-title aria-label="x-card-title">
        <slot />
    </v-card-title>
</template>


<script>
export default {
    name: "XCardTitle", 
    props: {}
}
</script>