import axios from "axios";
import Links from "../constants/Links";

/**
 * @description contains apis fpr user management
 */
const User = {
    update: async (data) => {
        return axios.put(Links.user.update, data);
    },

    dashboard: async () => {
        return axios.get(Links.user.dashboard)
    },

    /**
     * @description Locks user's account.
     * @param userId the ID of the user
     * @returns {Promise<AxiosResponse<*>>} containing the user
     */
    lockAccount: async (userId) => {
        return axios.put(Links.user.lockAccount + '/' + userId)
    },

    /**
     * @description Unlocks user's account.
     * @param userId the ID of the user
     * @returns {Promise<AxiosResponse<*>>} containing the user
     */
    unlockAccount: async (userId) => {
        return axios.put(Links.user.unlockAccount + '/' + userId)
    },
}

export default User
