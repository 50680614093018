import axios from "axios";
import Links from "../constants/Links";
import links from "../constants/Links";

const ProgrammeSettings = {
    fetchForProgrammeLevel: async (programme, level, semester) => {
        return axios.get(Links.courseSettings.fetchForProgrammeLevel + '?programme=' + programme + '&level=' + level + '&semester=' + semester)
    },

    fetchUnregisteredForProgrammeLevel: async (programme, level, semester) => {
        return axios.get(Links.courseSettings.fetchUnregisteredForProgrammeLevel + '?programme=' + programme + '&level=' + level + '&semester=' + semester)
    },

    addCoursesToProgramme: async (programmeId, data) =>{
        return axios.post(Links.courseSettings.addCoursesToProgramme + programmeId, data)
    },

    deleteCoursesToProgramme: async (programmeId) =>{
        return axios.delete(Links.courseSettings.deleteCourseFromProgramme + programmeId)
    },

    updateProgrammeSemesterSetting: async (semesterSettingId, data) => {
        return axios.put(Links.courseSettings.updateProgrammeSemesterSetting + semesterSettingId, data )
    },

    updateCreditUnit: async (data) => {
        return axios.post(links.courseSettings.updateCourse, data)
    },
}

export default ProgrammeSettings