import axios from "axios";
import Links from "@/shared/constants/Links";

const ScreeningReason = {
    create: async (data) => {
        return axios.post(Links.reason.create, data)
    },

    update: async (id, data) => {
        return axios.put(Links.reason.update + '/' + id, data)
    },

    delete: async (id) => {
        return axios.delete(Links.reason.delete + '/' + id)
    },

    fetchAll: async () => {
        return axios.get(Links.reason.fetchAll)
    }
}

export default ScreeningReason