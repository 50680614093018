import axios from "axios"
import Links from "../constants/Links"

const ProgrammeLevel = {
    find: async (programme, level) => {
        return axios.get(Links.programmeLevel.find + '?programme=' + programme + '&level=' + level)
    },

    update: async (id, data) => {
        return axios.put(Links.programmeLevel.update + '/' + id, data)
    }
}

export default ProgrammeLevel