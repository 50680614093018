import axios from 'axios'
import Links from '../constants/Links'

const Illness = {
    /**
     * @description Creates an illness
     * @param data An object containing 
     * @returns 
     */
    create: async (data) => {
        return axios.post(Links.illness.create, data)
    },

    /**
     * @description Updates an existing illness
     * @param id ID of the illness to update
     * @param data An object containing 
     * @returns 
     */
     update: async (id, data) => {
        return axios.put(Links.illness.update + '/' + id, data)
    },

    /**
     * @description Deletes an illness
     * @param id ID of the illness to delete
     * @returns 
     */
    delete: async (id) => {
        return axios.delete(Links.illness.delete + '/' + id)
    },

    /**
     * @description Gets all illnesses
     * @param page the page to get
     * @param size the size to get
     * @returns 
     */
    all: async (page, size) => {
        return axios.get(Links.illness.all + '?page=' + page + '&size=' + size)
    }
}

export default Illness