import axios from "axios";
import Links from "../constants/Links";

const Signature = {
    setSignature: async (data) => {
        return axios.post(Links.signature.setSignature, data)
    },

    getSignature: async (userId) => {
        return axios.get(Links.signature.getSignature + userId)
    }
}

export default Signature