import axios from "axios";
import Links from "../constants/Links";

/**
 * @param applicant API file for Application Module
 *
 */

const Applicants = {
    all: async(reqParams, page, itemsPerPage) => {
        let params = '?session=' + reqParams.session
        if(reqParams.status){
            params = params +  '&student_status=' + reqParams.status
        }
        if(reqParams.faculty){
            params = params +  '&faculty=' + reqParams.faculty
        }
        if(reqParams.department){
            params = params +  '&department=' + reqParams.department
        }
        if(reqParams.programme){
            params = params +  '&programme=' + reqParams.programme
        }
        if(reqParams.country){
            params = params +  '&country=' + reqParams.country
        }
        if(reqParams.state){
            params = params +  '&state=' + reqParams.state
        }
        if(reqParams.lga){
            params = params +  '&lga=' + reqParams.lga
        }
        if(reqParams.gender){
            params = params +  '&gender=' + reqParams.gender
        }

        return axios.get(Links.applicants.all + params + "&page="+page+"&size="+itemsPerPage);
    },

    validateUTME: async (data) => {
        return axios.post(Links.applicants.validateUTME, data)
    },

    getInitialData: async () =>  {
        return axios.get(Links.applicants.getInitialData);
    },

    register: async (data) => {
        return axios.post(Links.applicants.register, data)
    },

    personalDetailsUpdate: async (applicant, data) => {
        return axios.put(Links.applicants.personalDetailsUpdate + '/' + applicant, data)
    },

    contactDetailsUpdate: async (applicant, data) => {
        return axios.put(Links.applicants.contactDetailsUpdate + '/' + applicant, data)
    },

    nextOfKinUpdate: async (applicant, data) => {
        return axios.put(Links.applicants.nextOfKinUpdate + '/' + applicant, data)
    },

    ssceUpdate: async (applicant, data) => {
        return axios.put(Links.applicants.ssceUpdate + '/' + applicant, data, {
            headers: { 'Content-Type': 'multipart/form-data' },
        })
    },

    utmeUpdate: async (applicant, data) => {
        return axios.put(Links.applicants.utmeUpdate + '/' + applicant, data)
    },

    nyscUpdate: async (applicant, data) => {
        return axios.put(Links.applicants.nyscUpdate + '/' + applicant, data)
    },

    /**
     * @description Updates applicant qualificationmdata
     * @param applicantId the applicant id
     * @param data a form data containging results(a stringified javascript object) and files(an array of files)
     * @returns
     */
    qualificationUpdate: async (applicantId, data) => {
        return axios.put(Links.applicants.qualificationUpdate + '/' + applicantId, data, {
            headers: { 'Content-Type': 'multipart/form-data' },
        })
    },

    programmeUpdate: async (applicant, data) => {
        return axios.put(Links.applicants.programmeUpdate + '/' + applicant, data)
    },

    getUTMEDetails: async (applicant) => {
        return axios.get(Links.applicants.getUTMEDetails + '/' + applicant)
    },

    getSSCEDetails: async (applicant) => {
        return axios.get(Links.applicants.getSSCEDetails + '/' + applicant)
    },

    getNYCEDetails: async (applicant) => {
        return axios.get(Links.applicants.getNYCEDetails + '/' + applicant)
    },

    getQualificationDetails: async (applicant) => {
        return axios.get(Links.applicants.getQualificationDetails + '/' + applicant)
    },

    initializeApplicationFeeTransaction: async (applicant) => {
        return axios.put(Links.applicants.initializeApplicationFeeTransaction + '/' + applicant)
    },

    initializeScreeningTransaction: async (applicant) => {
        return axios.put(Links.applicants.initializeScreeningFeeTransaction + '/' + applicant)
    },

    initializeAcceptanceFeeTransaction: async (applicant) => {
        return axios.put(Links.applicants.initializeAcceptanceFeeTransaction + '/' + applicant)
    },

    uploadUTME: async (data) => {
        return axios.post(Links.applicants.uploadUTME, data, {
            headers: { 'Content-Type': 'multipart/form-data' },
        })
    },

    uploadSSCE: async (data) => {
        return axios.post(Links.applicants.uploadSSCE, data, {
            headers: { 'Content-Type': 'multipart/form-data' },
        })
    },

    uploadInformation: async (data) => {
        return axios.post(Links.applicants.uploadInformation, data, {
            headers: { 'Content-Type': 'multipart/form-data' },
        })
    },

    uploadStatus: async (data, session) => {
        return axios.post(Links.applicants.uploadStatus + '/' + session, data, {
            headers: { 'Content-Type': 'multipart/form-data' },
        })
    },

    /**
     * @description Api call to upload applicant's qualifications data
     * @param data form data containing session(session id) and file(excel file)
     * @returns upload report
     */
    uploadQualifications: async (data) => {
        return axios.post(Links.applicants.uploadQualifications, data, {
            headers: { 'Content-Type': 'multipart/form-data' },
        })
    },

    /**
     * @description Api call to upload applicant's qualifications documents
     * @param data form data containing applicant(db id) and files(map documents)
     * @returns upload report
     */
    uploadQualificationsDocuments: async (id, data) => {
        return axios.post(Links.applicants.uploadQualificationsDocuments + '/' + id, data, {
            headers: { 'Content-Type': 'multipart/form-data' },
        })
    },

    recordSheet: async (reqParams) => {
        let params = '?session=' + reqParams.session
        if(reqParams.status){
            params = params +  '&student_status=' + reqParams.status
        }
        if(reqParams.faculty){
            params = params +  '&faculty=' + reqParams.faculty
        }
        if(reqParams.department){
            params = params +  '&department=' + reqParams.department
        }
        if(reqParams.programme){
            params = params +  '&programme=' + reqParams.programme
        }
        if(reqParams.country){
            params = params +  '&country=' + reqParams.country
        }
        if(reqParams.state){
            params = params +  '&state=' + reqParams.state
        }
        if(reqParams.lga){
            params = params +  '&lga=' + reqParams.lga
        }
        if(reqParams.gender){
            params = params +  '&gender=' + reqParams.gender
        }

        return axios.get(Links.applicants.recordSheet + params, {responseType: 'blob'})
    },

    qualificationDocumentsFor: async (applicantId) => {
        return axios.get(Links.applicants.qualificationDocument + '?applicant=' + applicantId, {responseType: 'blob'})
    },

    ssceScreeningReportSheet: async (reqParams) => {
        let params = '?session=' + reqParams.session
        if(reqParams.status){
            params = params +  '&student_status=' + reqParams.status
        }
        if(reqParams.faculty){
            params = params +  '&faculty=' + reqParams.faculty
        }
        if(reqParams.department){
            params = params +  '&department=' + reqParams.department
        }
        if(reqParams.programme){
            params = params +  '&programme=' + reqParams.programme
        }
        if(reqParams.country){
            params = params +  '&country=' + reqParams.country
        }
        if(reqParams.state){
            params = params +  '&state=' + reqParams.state
        }
        if(reqParams.lga){
            params = params +  '&lga=' + reqParams.lga
        }
        if(reqParams.gender){
            params = params +  '&gender=' + reqParams.gender
        }
        return axios.get(Links.applicants.ssceScreeningReportSheet + params, {responseType: 'blob'})
    },

    qualificationReportSheet: async (reqParams) => {
        let params = '?session=' + reqParams.session
        if(reqParams.status){
            params = params +  '&student_status=' + reqParams.status
        }
        if(reqParams.faculty){
            params = params +  '&faculty=' + reqParams.faculty
        }
        if(reqParams.department){
            params = params +  '&department=' + reqParams.department
        }
        if(reqParams.programme){
            params = params +  '&programme=' + reqParams.programme
        }
        if(reqParams.country){
            params = params +  '&country=' + reqParams.country
        }
        if(reqParams.state){
            params = params +  '&state=' + reqParams.state
        }
        if(reqParams.lga){
            params = params +  '&lga=' + reqParams.lga
        }
        if(reqParams.gender){
            params = params +  '&gender=' + reqParams.gender
        }
        return axios.get(Links.applicants.qualificationReportSheet + params, {responseType: 'blob'})
    },

    rejectAdmission: async (applicantid) => {
        return axios.put(Links.applicants.rejectAdmission + '/' + applicantid)
    },

    acceptAdmission: async (applicantId) => {
        return axios.put(Links.applicants.acceptAdmission + '/' + applicantId)
    },

    // next application operations
    nextToContactDetails: async (applicantId) => {
        return axios.put(Links.applicants.nextToContactDetails + '/' + applicantId)
    },

    nextToNextOfKinDetails: async (applicantId) => {
        return axios.put(Links.applicants.nextToNextOfKinDetails + '/' + applicantId)
    },

    nextToProgrammeUpdate: async (applicantId) => {
        return axios.put(Links.applicants.nextToProgrammeUpdate + '/' + applicantId)
    },

    nextToApplicationFee: async (applicantId) => {
        return axios.put(Links.applicants.nextToApplicationFee + '/' + applicantId)
    },

    nextToScreeningFee: async (applicantId) => {
        return axios.put(Links.applicants.nextToScreeningFee + '/' + applicantId)
    },

    nextToUTMEUpdate: async (applicantId) => {
        return axios.put(Links.applicants.nextToUTMEUpdate + '/' + applicantId)
    },

    nextToSSCEUpdate: async (applicantId) => {
        return axios.put(Links.applicants.nextToSSCEUpdate + '/' + applicantId)
    },

    nextToNYSCUpdate: async (applicantId) => {
        return axios.put(Links.applicants.nextToNYSCUpdate + '/' + applicantId)
    },

    nextToQualificationUpdate: async (applicantId) => {
        return axios.put(Links.applicants.nextToQualificationUpdate + '/' + applicantId)
    },

    toggleClear: async (applicantId, data) => {
        return axios.put(Links.applicants.toggleClear + '/' + applicantId, data)
    },

    revokeClearance: async (applicantId) => {
        return axios.put(Links.applicants.revokeClearance + '/' + applicantId)
    },

    /**
     * @description Download application fee invoice
     * @param applicantId applicant's DB id
     * @returns {Promise<AxiosResponse<any>>}
     */
    applicationInvoice: async (applicantId) => {
        return axios.get(Links.applicants.applicationInvoice + '/' + applicantId, {
            responseType: 'blob'
        })
    },

    /**
     * @description Download screening fee invoice
     * @param applicantId applicant's DB id
     * @returns {Promise<AxiosResponse<any>>}
     */
    screeningInvoice: async (applicantId) => {
        return axios.get(Links.applicants.screeningInvoice + '/' + applicantId, {
            responseType: 'blob'
        })
    },

    /**
     * @description Download acceptance fee invoice
     * @param applicantId applicant's DB id
     * @returns {Promise<AxiosResponse<any>>}
     */
    acceptanceInvoice: async (applicantId) => {
        return axios.get(Links.applicants.acceptanceInvoice + '/' + applicantId, {
            responseType: 'blob'
        })
    },

    /**
     * @description Downloads application summary for applicant
     * @param applicant applicant ID
     * @returns {Promise<AxiosResponse<any>>}
     */
     applicationSummary: async (applicant) => {
        return axios.get(Links.applicants.applicationSummary + '?applicant=' + applicant, {
            responseType: 'blob'
        })
    },

    /**
     * @description Downloads screening form for applicant
     * @param applicantId applicant's user ID
     * @returns {Promise<AxiosResponse<any>>}
     */
     screeningForm: async (applicantId) => {
        return axios.get(Links.applicants.screeningForm + '?applicant=' + applicantId, {
            responseType: 'blob'
        })
    },

    /**
     *
     * @param id the DB id of the applicant
     * @param data object containing following props: status(number)
     * @returns the updated applicant
     */
    updateApplicantStatus: async (id, data) => {
        return axios.put(Links.applicants.updateStatus + '/' + id, data)
    },

    /**
     * @description Updates applicants phone number
     * @param applicantId the id of the applicant
     * @param data an object containing one attribute: phone
     * @returns {Promise<AxiosResponse<any>>}
     */
    updatePhone: async (applicantId, data) => {
        return axios.put(Links.applicants.updatePhone + '/' + applicantId, data)
    },

    /**
     * @description Finds an applicant
     * @param programmeTypeId
     * @param query
     * @returns {Promise<AxiosResponse<any>>}
     */
    find: async (session, query) => {
        return axios.get(Links.applicants.find + '?session=' + session + '&q=' + query)
    },

    /**
     * @description Steps an applicant up
     * @param applicantId ID of the applicant to step up
     * @returns
     */
    stepApplicant: async (applicantId, data) => {
        return axios.put(Links.applicants.stepApplicant + '/' + applicantId, data)
    },

    /**
     * @description Updates an applicant
     * @param id User ID of the applicant to update
     * @param data an object containing the ffg: firstName, lastName, middleName, contactAddress, homeTown, phone,
     *             dob(Date of birth), gender(Gender id), lga(LGA id), contactLga(LGA id)
     * @returns
     */
    updateApplicant: async (id, data) => {
        return axios.put(Links.applicants.updateApplicant + '/' + id, data)
    },

    /**
     * @description Api to screen an applicant
     * @param id the applicant resource id
     * @param data an object containin the following: reason(String) and status (numeric value of disqualify or qualify)
     * @returns {Promise<AxiosResponse<any>>}
     */
    screenApplicant: async (id, data) => {
        return axios.put(Links.applicants.screenApplicant + '/' + id, data)
    },

    /**
     * @description Api to run a check on applicant subject combinations and compute their PUTME Score
     * @param id the applicant user id
     * @returns {Promise<AxiosResponse<any>>}
     */
    runCombinationCheck: async (id) => {
        return axios.get(Links.applicants.runCombinationCheck + '?applicant=' + id)
    },

    /**
     * @description Resets applicant's application
     * @param data an object containing the applicant userid(as applicant) and admission resource id(as admission)
     * @returns {Promise<AxiosResponse<any>>}
     */
    resetApplication: async (data) => {
        return axios.post(Links.applicants.resetApplication, data)
    },

    /**
     * @description Upgrades applicant to a student
     * @param data an object containing the applicant id(applicant's userId)
     * @returns {Promise<AxiosResponse<any>>}
     */
    upgradeApplicantToStudent: async (data) => {
        return axios.post(Links.applicants.upgradeToStudent, data)
    },

    /**
     * @description Generate userId for student
     * @param data an object containing the applicant id(applicant's userId)
     * @returns {Promise<AxiosResponse<any>>}
     */
    generateId: async (data) => {
        return axios.post(Links.applicants.generateId, data)
    },

    updateStartDetails: async (levelId, sessionId, programme, applicant) => {
        return axios.post(Links.applicants.updateStartDetails, {
            level: levelId,
            session: sessionId,
            programme: programme,
            applicant: applicant
        })
    },

    /**
     * @description Updates applicants jamb reg
     * @param {string} reg Applicant ID or Jamb reg of the applicant(if applicant ID is not available for applicant yet)
     * @param {string} newJambReg The new jamb reg to update to
     * @returns {Promise<AxiosResponse<any>>}
     */
    updateJambReg: async (reg, newJambReg) => {
        return axios.post(Links.applicants.updateJambReg, {
            applicant: reg,
            newJambReg: newJambReg
        });
    }
}

export default Applicants;
