import Vue from 'vue'
import Application from "./Application.vue";
import ApplicationOther from "./ApplicationV2.vue";
import vuetify from '../plugins/vuetify'
import vuetifyOther from '../plugins/lafiaPlugin';
import store from './store'
import router from './routes'
import routerOther from './routes/other'
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import Defaults from '@/shared/Defaults';
import axios from 'axios';
import AppTypes from "@/shared/constants/apptypes";
import Toast from "vue-toastification";
//import 'vue-toast-notification/dist/theme-default.css';
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";



if(window.API){
    // set default api
    Defaults.api = window.API
    // set up axios api
    axios.defaults.baseURL = window.API
} else{
    axios.defaults.baseURL = Defaults.api // use api for test
}

let nsuk = AppTypes.CURRENT === AppTypes.NSUK

Vue.config.productionTip = false
if(nsuk)
    Vue.use(VueToast);
else
    Vue.use(Toast, {
        transition: "Vue-Toastification__fade",
        maxToasts: 20,
        newestOnTop: true
    })

new Vue({
    store,
    vuetify: nsuk ? vuetify : vuetifyOther,
    router: nsuk ? router : routerOther,
    render: h => h(nsuk ? Application : ApplicationOther)
}).$mount('#app')
