import Vue from "vue"; 
import Vuex from "vuex"; 
import transcriptModule from "./modules/store"


Vue.use(Vuex); 

export default new Vuex.Store({
  modules: {
    transcriptModule
  },
  
}); 
