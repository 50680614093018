<template>
  <div class="text-center">
    <v-snackbar
        v-model="visible"
        :multi-line="false"
        timeout="15000"
        :color="type"
        bottom
        right

        transition="slide-x-transition"
    >
      {{ '' }}

      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            @click="close"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: "MessageBox",
  props: {
    show: Boolean,
    message: String,
    type: String, // success, warning or error
  },

  data: () => ({
    visible: false
  }),

  watch: {
    show: function(newVal){
      if(newVal){
        this.$toast(this.message, {
          type: this.type,
          position: "top-center",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: true,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false
        });

        this.close()
      }
    }
  },

  methods: {
    close(){
      // this.$props.show = false;
      this.$emit('close-message');
    }
  },

  computed: {
    icon: function(){
      return (this.type==='success') ? 'check-circle':(this.type === 'error' ? 'alert-circle' : (this.type === 'info' ? "information" : 'alert'))
    }
  }
}
</script>

<style scoped>
  * {
    color: white;
  }
</style>
