import axios from "axios";
import Links from "../constants/Links";

const Department = {
    create: async (data) => {
        return axios.post(Links.department.create, data)
    },

    update: async (data) => {
        return axios.put(Links.department.update, data);
    },

    delete: async (id) => { 
        return axios.delete(Links.department.delete + '/' + id)
    },

    all: async () => {
        return axios.get(Links.department.all)
    },

    allForFaculty: async (faculty) => {
        return axios.get(Links.department.allForFaculty + faculty)
    },

    allWithHods: async () => {
        return axios.get(Links.department.allWithHods)
    },

    allForCurrentUser: async (user) => {
        return axios.get(Links.department.allForCurrentUser + '?staff=' + user)
    },

    allForCurrentUserAndFaculty: async (user, faculty) => {
        return axios.get(Links.department.allForCurrentUserAndFaculty + '?staff=' + user + '&faculty=' + faculty)
    },

    allHods: async () => {
        return axios.get(Links.department.allHods)
    },

    setSignature: async (data) => {
        return axios.post(Links.department.setSignature, data)
    },

    getHod: async (departmentId) =>{
        return axios.get(Links.department.getHod + departmentId)
    },

    openResultAccess: async (departmentId) => {
        return axios.put(Links.department.openAccess + departmentId)
    },

    closeResultAccess: async (departmentId) => {
        return axios.put(Links.department.closeAccess + departmentId)
    },

    graduationService: async (programmeTypeId, departmentId) => {
        return axios.put(Links.department.graduationService + programmeTypeId + '/' + departmentId)
    },

    getSemesterSettings: async (department, semester) => {
        return axios.get(Links.department.getSemesterSettings + '?department=' + department + '&semester='+semester)
    },

    toggleResultUpload: async (department, semester) => {
        return axios.put(Links.department.toggleResultUpload + '/' + department + '/'+semester)
    },

    toggleResultChecking: async (department, semester) => {
        return axios.put(Links.department.toggleResultChecking + '/' + department + '/'+semester)
    },

    getAddedSubjects: async (department) => {
        return axios.get(Links.department.getAddedSubjects + '/' + department)
    },

    addSubject: async (department, data) => {
        return axios.put(Links.department.addSubject + '/' + department, data)
    },

    addAllSubjects: async (department, data) => {
        return axios.put(Links.department.addAllSubject + '/' + department, data)
    },

    removeSubject: async (department, data) => {
        return axios.put(Links.department.removeSubject + '/' + department, data)
    },

    toggleSubjectCompulsory: async (itemId) => {
        return axios.put(Links.department.toggleCompulsory + '/' + itemId)
    },

    updateSubjectsGrades: async (itemId, data) => {
        return axios.put(Links.department.updateSubjectsGrades + '/' + itemId, data)
    },

    getSubjectsForApplications: async (department) => {
        return axios.get(Links.department.getAllSubjectsForApplication + '/' + department)
    },
}

export default Department