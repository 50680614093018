<template>
    <div>
        <x-card :outlined="true">
            <!-- TITLE-->
            <v-row>
                <v-col cols="12" md="4">
                    <x-card-title >
                        Course Registration
                    </x-card-title>
                </v-col>

                <v-col cols="12" md="5" class="d-flex ml-md-auto mt-2 mx-auto mx-md-2">
                    <x-select class="ml-1 mr-md-0" placeholder="Session" rounded outlined dense :items="sessions" v-model="selectedSession" return-object item-text="name" placholder="Session"/>
                    <x-select class="ml-md-2 ml-2 mr-1" rounded outlined dense :items="semesters" v-model="selectedSemester" return-object item-text="title" placeholder="Semester"/>
                </v-col>
            </v-row>

            <v-divider />

            <x-card-text>

                <div v-if="isMobile" class="d-flex ">
                    <v-img :src="logo" alt="logo"  contain :width="isMobile ? 50 : 100" :height="isMobile ? 50 : 100"/>
                    
                    <div class="d-block">
                        <h2 class="text-black text-center">{{ getGlobal != null ? getGlobal.institutionName : 'School name' }}</h2>
                        <p class="my-2 font-weight-medium">Student's Course Registration Form</p>
                        <p class="font-weight-light"> 
                                {{ getUser != null ? getUser.programme.programmeType.school.name : 'School name' }}
                            </p>
                    </div>
                    <v-img contain  :width="isMobile ? 50 : 100" :height="isMobile ? 50 : 100" :src="image" style="border-radius: 10px; border: #c49a28 2px solid"/>
                </div>


                <div class="d-flex" v-else>
                    <div class="d-flex justify-start">
                        <v-img :src="logo" alt="logo"  contain :width="isMobile ? 50 : 100" :height="isMobile ? 50 : 100"/>
                    </div>
                   
                    <!-- SCHOOL NAME -->



                    <div class="d-md-block text-center align-center ml-auto mt-5">
                        <h1 class="text-black">{{ getGlobal != null ? getGlobal.institutionName : 'School name' }}</h1>
                        <p class="my-2 font-weight-medium">Student's Course Registration Form</p>
                        <p class="font-weight-light"> 
                            {{ getUser != null ? getUser.programme.programmeType.school.name : 'School name' }}
                        </p>
                    </div>

                    <div class="d-flex ml-auto">
                        <v-img contain  :width="isMobile ? 50 : 100" :height="isMobile ? 50 : 100" :src="image" style="border-radius: 10px; border: #c49a28 2px solid"/>
                    </div>
                </div>

                <!-- RESULT HERE -->
                <v-sheet outlined rounded class="mt-8" v-if="user != null">
                    <v-simple-table dense >
                      <tbody>
                        <tr>
                          <th style="width: 15%">Name</th>
                          <td style="width: 35%">{{ user.name }}</td>
                          <th style="width: 15%">Matric:</th>
                          <td style="width: 30%">{{ user.userId }}</td>
                        </tr>
                        <tr>
                          <th style="width: 15%">Faculty</th>
                          <td style="width: 35%" class="text-left">
                            {{ user.programme.department.faculty.name }}
                          </td>
                          <th style="width: 15%">Department:</th>
                          <td style="width: 30%">
                            {{ user.programme.department.name }}
                          </td>
                        </tr>
                        <tr>
                          <th style="width: 15%">Programme</th>
                          <td style="width: 35%" class="text-left">
                            {{ user.programme.name }}
                          </td>
                          <th style="width: 15%">Level</th>
                          <td style="width: 30%">{{ user.level.title }}</td>
                        </tr>
                        <tr>
                          <th style="width: 15%">Session</th>
                          <td style="width: 35%" class="text-left">
                            {{
                              selectedSession != null
                                ? selectedSession.name
                                : ""
                            }}
                          </td>
                          <th style="width: 15%">Date:</th>
                          <td style="width: 30%">
                            {{ new Date().toLocaleString() }}
                          </td>
                        </tr>
                      </tbody>
                    </v-simple-table>

                    <v-sheet outlined rounded class="mx-auto">
                        <v-simple-table dense>
                        <thead>
                            <tr>
                            <th>Course</th>
                            <th>Title</th>
                            <th>Credit Load</th>
                            <th class="text-right">Lecturer's Sign.</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                            v-for="(item, index) in registeredCourses"
                            :key="index"
                            >
                            <td style="font-size: 0.8em" class="font-weight-bold">
                                {{ item.courseCode }}
                            </td>
                            <td style="font-size: 0.8em" class="">
                                {{ item.title }}
                            </td>
                            <td style="font-size: 0.8em" class="">
                                {{ item.creditUnit }}
                            </td>
                            <td></td>
                            </tr>
                        </tbody>
                        </v-simple-table>
                    </v-sheet>
                </v-sheet>

                <v-sheet outlined rounded class="mt-8" v-else>
                    <v-simple-table dense>
                        <tbody>
                            <tr>
                                <th>Name</th>
                                <th>Course</th>
                                <th>Matric</th>
                                <th>ID</th>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </v-sheet>
            </x-card-text>
        </x-card>

        
        <loading-dialog :show="loading"></loading-dialog>
        <message-box
            :show="messageShow"
            :message="message"
            :type="messageType"
            v-on:close-message="messageShow = !messageShow"
        ></message-box>
    </div>
</template>

<script>
import { mapGetters } from "vuex"; 
import XCardTitle from "ui/components/XCardTitle.vue";
import XCard from 'ui/components/XCard.vue';
import XCardText from 'ui/components/XCardText.vue';
import XCardAction from 'ui/components/XCardAction.vue';
import LoadingDialog from "ui/lafia/components/LoadingDialog.vue";
import MessageBox from "@ui/components/MessageBox";
import XSelect from 'ui/widgets/XSelect.vue';
import Defaults from 'shared/Defaults';
import Links from "shared/constants/Links";
import Api from "main/api";

export default {
    name: "CourseRegistration", 
    components: {
        LoadingDialog,
        MessageBox,
        XCard, 
        XCardTitle,
        XCardText, 
        XSelect, 
        XCardAction
    }, 
    

    data: () => ({
        dataValid: true,
        semesters: [],
        sessions: [],
        selectedSemester: null,
        selectedSession: null,
        registeredCourses: [],
        messageType: '',
        message: '',
        messageShow: false,
        loading: false,
        hodSignature: '',
        logo: Defaults.api + Links.global.logo,
        user: null,
        selectedDocument: 1,
        image: '',
        printing: false,
    }), 

    methods: {
        loadCourses() {
            if(this.selectedSemester != null && this.selectedSession != null){
                this.loading = true; 
                this.registeredCourses = []; 
                let uId = this.getUser.userId; 
                let session = this.selectedSession.id; 
                let semester = this.selectedSemester.id; 
                Api.students
                    .getRegisteredCoursesForSemester(uId, session, semester)
                    .then((res) => {
                        this.registeredCourses = res.data;
                    })
                    .catch((err) => ErrorResolver.checkErrors(this, err))
                    .finally(() => (this.loading = false));
            }
        },

        fetchSessionsForPayment() {
        if (this.getUser != null) {
            this.loading = true;
            Api.session.getRegisteredSessionsForStudent(this.getUser.userId)
            .then(res => this.sessions = res.data)
            .catch(err => ErrorResolver.checkErrors(this, err))
            .finally(() => this.loading = false);
        }
        },
    },


    computed: {
        ...mapGetters(['getGlobal', 'getUser']),

        creditLoad: function(){
            if(this.registeredCourses.length === 0)
                return 0;

            return this.registeredCourses.map(e => e.creditUnit).reduce((p, c) => p + c, 0)
        },

        isMobile: function() {
            return this.$vuetify.breakpoint.xs;
        }
    }, 

    watch: {
        selectedSession: function(val){
            if(val != null){
                this.semesters = val.semesters
                this.loadCourses()
            }
        }, 

        selectedSemester: function(){
            this.loadCourses()
        },

        courses: function(newV){
            this.registeredCourses = newV;
        },

        getUser: function(val){
            if(val != null){
                this.user = val
                this.fetchSessionsForPayment()
                this.image = Defaults.api + '/profile-picture/fetch?user=' +  this.val.userId;
            }
        },

        user: function(){
            if(this.user != null){
                this.image = Defaults.api + '/profile-picture/fetch?user=' +  this.user.userId;
            }
        }
    }, 

    created() {
        if(this.getUser != null){
            this.image = Defaults.api + '/profile-picture/fetch?user=' +  this.getUser.userId;
        }


        this.fetchSessionsForPayment(); 
    }
}
</script>