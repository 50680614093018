import TextUtil from "@/shared/TextUtil";
import {getBrowserInfo} from "@/shared/BrowserUtil";

const TabUtil = {
    openNew(key, key2, link){
        let info = `${getBrowserInfo().userAgent}`
        let data = {
            token: TextUtil.generateUID(15),
            auth: TextUtil.generateUID(20),
            rf: key,
            df: key2,
            info: info
        };
        let dataString = JSON.stringify(data);
        let dataStringBase64 = window.btoa(dataString); // (optional)
        let dataStringBase64Safe = encodeURIComponent(dataStringBase64);
        let url = `${link}?data=${dataStringBase64Safe}`;
        window.open(url, '_blank');
    },

    extractData(data){
        let urlParams = new URLSearchParams(data); // supported on most modern browsers
        let dataStringBase64Safe = urlParams.get('data');
        let dataStringBase64 = decodeURIComponent(dataStringBase64Safe);
        let dataString = window.atob(dataStringBase64);
        let object = JSON.parse(dataString);
        return {
            key: object.rf,
            key2: object.df,
            info: object.info
        }
    }
}
 export default TabUtil
