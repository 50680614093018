import axios from "axios"
import Links from "../constants/Links"

/**
 * @description Contains api calls to honours endpoints
 */
const Honour = {
    /**
     * @description Creates a new Honour
     * @param data an object containing the following properties (name, value(number), programmeTypeId(id of the programme type))
     * @returns the newly created honour
     */
    create: async (data) => {
        return axios.post(Links.honours.create, data)
    },

    /**
     * @description Updates an existing Honour
     * @package id of the honour to update
     * @param data an object containing the following properties (name, value(number), programmeTypeId(id of the programme type))
     * @returns the newly created honour
     */
     update: async (id, data) => {
        return axios.put(Links.honours.update + '/' + id, data)
    },

    /**
     * Returns all honours based on the supplied programme type
     * @param programmeTypeId the id of the programme type to fetch for
     * @returns List of honours
     */
    fetch: async (programmeTypeId) => {
        return axios.get(Links.honours.fetch + '?programme_type=' + programmeTypeId)
    }
}

export default Honour