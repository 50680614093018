import axios from "axios";
import Links from "../constants/Links";

const Hostel = {
    /**
     * @description return hostel config. object contain fee, privileged levels
     * @returns {Promise<AxiosResponse<any>>}
     */
    getSettings: async () => {
        return axios.get(Links.hostel.getSettings)
    },

    updateFee: async (data) => {
        return axios.post(Links.hostel.updateFee, data)
    },

    createBlock: async (data) => {
        return axios.post(Links.hostel.createBlock, data)
    },

    allBlocks: async (gender) => {
        return axios.get(Links.hostel.allBlocks + (gender ? '?gender=' + gender : ''))
    },

    allBlocksPaginate: async (gender, page, size) => {
        return axios.get(Links.hostel.allBlocksPaginate + '?page=' + page + '&size=' + size + (gender ? '&gender=' + gender : ''))
    },

    createRoom: async (data) => {
        return axios.post(Links.hostel.createRoom, data)
    },

    allRooms: async (block) => {
        return axios.get(Links.hostel.allRooms + (block ? '?block=' + block : ''))
    },

    allRoomsPaginated: async (block, page, size) => {
        return axios.get(Links.hostel.allRoomsPaginated + '?page=' + page + '&size=' + size + (block ? '&block=' + block : ''))
    },

    createRoomSpace: async (data) => {
        return axios.post(Links.hostel.createRoomSpace, data)
    },

    allRoomSpaces: async (room, page, size) => {
        return axios.get(Links.hostel.allRoomSpaces + '?page=' + page + '&size=' + size + (room ? '&room=' + room : ''))
    },

    /**
     * @description Reserve room space for student.
     * @param space the space DB id
     * @param data the data containing the student id. property required is id(student's matric).
     * @returns {Promise<AxiosResponse<any>>}
     */
    reserveRoomSpace: async (space, data) => {
        return axios.put(Links.hostel.reserveRoomSpace + '/' + space, data)
    },

    cancelReservation: async (space) => {
        return axios.put(Links.hostel.cancelReservationRoomSpace + '/' + space)
    },

    allocateRoom: async (space, data) => {
        return axios.put(Links.hostel.allocateSpace + '/' + space, data)
    },

    revokeAllocations: async () => {
        return axios.put(Links.hostel.revokeAllocations)
    },

    /**
     * @description returns all available spaces for student.
     * @param studentId the student ID
     * @returns {Promise<AxiosResponse<any>>} containing the room space
     */
    getAvailableSpaces: async (studentId) => {
        return axios.get(Links.hostel.getAvailableSpaces + '?student=' + studentId)
    },

    /**
     * @description Initializes hostel payment transaction
     * @param data the object containing the following properties: studentId and sessionId. the former is the matric no of the student while the latter is the session's DB id
     * @returns {Promise<AxiosResponse<any>>} containing payment object
     */
    initializeTransaction: async (data) => {
        return axios.post(Links.hostel.initializeTransaction, data)
    },

    /**
     * @description Verifies hostel payment with the given reference
     * @param reference the transaction reference
     * @returns {Promise<AxiosResponse<any>>} containing payment object
     */
    verifyPayment: async (reference) => {
        return axios.put(Links.hostel.verifyPayment + '/' + reference)
    },

    /**
     *
     * @param id the room space id
     * @param data the object containing the following properties: studentId and sessionId. the former is the matric no of the student while the latter is the session's DB id
     * @returns {Promise<AxiosResponse<any>>} containing the room space
     */
    selectSpace: async (id, data) => {
        return axios.put(Links.hostel.selectSpace + '/' + id, data)
    },

    /**
     * @description Returns student's hostel information for a session
     * @param studentId the userID (matric) of the student
     * @param sessionId the session's DB id
     * @returns {Promise<AxiosResponse<any>>} an object contaning payment information, eligibility and room space information.
     */
    getStudentInformation: async (studentId, sessionId) => {
        return axios.get(Links.hostel.getStudentInformation + '?student=' + studentId + '&session=' + sessionId)
    },

    /**
     * @description Adds a single level to privileged levels
     * @param data object containing the level's id to be added. property is levelId
     * @returns {Promise<AxiosResponse<any>>} contains hostel config
     */
    addLevel: async (data) => {
        return axios.post(Links.hostel.addLevel, data)
    },

    /**
     * @description Removes a single level from privileged levels
     * @param data object containing the level's id to be added. property is levelId
     * @returns {Promise<AxiosResponse<any>>} contains hostel config
     */
    removeLevel: async (data) => {
        return axios.post(Links.hostel.removeLevel, data)
    },

    invoice: async(studentId, sessionId) => {
        return axios.get(Links.hostel.invoice + '?user=' + studentId + '&session=' + sessionId, {
            responseType: 'blob'
        })
    }
}


export default Hostel