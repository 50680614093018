    <template>
  <v-text-field :placeholder="placeholder" v-on="$listeners" :outlined="true" :rounded="rounded" dense flat filled solo v-bind="$attrs" :value="value" @input="$emit('input', $event)"></v-text-field>
</template>

<script>
export default {
  name: "XTextField",
  props: {
    value: [Number, Object, String, Boolean, Array],
    rounded: {
      type: Boolean,
      default: true
    },
    placeholder: {
      type: String,
      default: ""
    }
  },

  methods: {
    onInputChange(event){
      this.$emit('input', event)
      if(this.value){
        this.value = event.target.value
      }
    }
  }
}
</script>

<style scoped>

</style>
