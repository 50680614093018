import axios from "axios"
import Links from "../constants/Links"

const Awards = {
    /**
     * @description Creates an award
     * @param data an object containing the following properties: name, value, code
     * @returns the newly created award
     */
    create: async (data) => {
        return axios.post(Links.awards.create, data)
    },

    /**
     * @description Updates an exsising award
     * @param id id of the award to update
     * @param data an object containing the following properties: name, value, code
     * @returns the updated award
     */
    update: async (id, data) => {
        return axios.put(Links.awards.update + '/' + id, data)
    },

    /**
     * @description deletes and award
     * @param id id of the award to delete
     * @returns 
     */
    delete: async (id) => {
        return axios.delete(Links.awards.delete + '/' + id)
    },

    /**
     * Fetch all awards
     * @param page the page number of begin with (starts with zero as usual)
     * @param size the size of the page
     * @returns 
     */
    allPaginated: async (page, size) => {
        return axios.get(Links.awards.allPaginated + '?page=' + page + '&size=' + size)
    },

    all: async () => {
        return axios.get(Links.awards.all)
    }
}

export default Awards