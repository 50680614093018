import axios from "axios";
import Links from "../constants/Links";

const BasicInformation = {
    getAll: async() => {
        return axios.get(Links.basicInformation.getAll)
    },

    updateGenders: async (data) => {
        return axios.post(Links.basicInformation.updateGenders, data)
    },


    updateTitles: async (data) => {
        return axios.post(Links.basicInformation.updateTitles, data)
    },

    
    updateReligions: async (data) => {
        return axios.post(Links.basicInformation.updateReligions, data)
    },


    updateRelationships: async (data) => {
        return axios.post(Links.basicInformation.updateRelationships, data)
    },


    updateMaritalStatuses: async (data) => {
        return axios.post(Links.basicInformation.updateMaritalStatuses, data)
    },


    updateGenotypes: async (data) => {
        return axios.post(Links.basicInformation.updateGenotypes, data)
    },
    
    updateBloodGroups: async (data) => {
        return axios.post(Links.basicInformation.updateBloodGroups, data)
    },

}

export default BasicInformation