import axios from "axios";
import Links from "../constants/Links";

const Course = {
    all: async (params) => {
        // programme typw
        let p = "?programme_type=" + params.programmeType
        // level
        if(params.level){
            p = p + "&level=" + params.level
        }
        // check faculty
        if(params.faculty){
            p = p + "&faculty=" + params.faculty
        }

        // check department
        if(params.department){
            p = p + "&department=" + params.department
        }

        // check department
        if(params.programme){
            p = p + "&programme=" + params.programme
        }

        p = p + "&strict=" + params.strict

        return axios.get(Links.course.all + p);
    },

    allForResultsManagement: async (params) => {
        // programme typw
        let p = "?programme_type=" + params.programmeType
        // level
        if(params.level){
            p = p + "&level=" + params.level
        }
        // check faculty
        if(params.faculty){
            p = p + "&faculty=" + params.faculty
        }

        // check department
        if(params.department){
            p = p + "&department=" + params.department
        }

        // check department
        if(params.programme){
            p = p + "&programme=" + params.programme
        }

        p = p + "&strict=" + params.strict

        return axios.get(Links.course.allForResultsManagement + p);
    },

    allDepartment: async (programmeType, departmentId) => {
        return axios.get(Links.course.allDepartment + "?programme_type=" + programmeType + '&department=' + departmentId);
    },

    allDepartmentForApproval: async (sessionId, semester, departmentId) => {
        return axios.get(Links.course.allDepartmentForApproval + sessionId + '/' + semester + '/' + departmentId);
    },

    allDepartmentForApprovalAtFaculty: async (sessionId, semester, departmentId) => {
        return axios.get(Links.course.allDepartmentForApproval + sessionId + '/' + semester + '/' + departmentId);
    },

    allProgramme: async (programmeId) => {
        return axios.get(Links.course.allProgramme + programmeId);
    },

    allProgrammeForApproval: async (programmeType, sessionId, semester, programmeId) => {
        return axios.get(Links.course.allProgrammeForApproval + programmeType + '/' + sessionId + '/' + semester + '/' + programmeId)
    },

    allForStudent: async (studentId, session, semester) => {
        return axios.get(Links.course.allForStudent + "?student=" + studentId +'&session=' + session + '&semester=' + semester);
    },

    allLecturers: async (courseId) => {
        return axios.get(Links.course.getLecturers + courseId);
    },

    assignLecturers: async (courseId, data) => {
        return axios.post(Links.course.assignLecturers + courseId, data)
    },

    assignMainLecturer: async (courseId, data) => {
        return axios.put(Links.course.setMainLecturer + courseId, data)
    },

    unAssignLecturers: async (courseId, data) => {
        return axios.put(Links.course.unAssignLecturers + courseId, data)
    },

    create: async (data) => {
        return axios.post(Links.course.create, data)
    },

    update: async (courseId, data) => {
        return axios.put(Links.course.update + courseId, data)
    },

    delete: async (courseId) => {
        return axios.delete(Links.course.delete + courseId)
    },

    getRegisteredStudents: async (sessionId, semester, courseId, page, size) => {
        return axios.get(Links.course.getRegisteredStudents + courseId + '/' + sessionId + '/' + semester + "?page=" + page + "&size=" + size)
    },

    getRegisteredStudentsDownload: async (sessionId, semester, courseId) => {
        return axios.get(Links.course.getRegisteredStudentsDownload + courseId + '/' + sessionId + '/' + semester, {
            responseType: 'blob'
        })
    },

    allPrerequisites: async (courseId) => {
        return axios.get(Links.course.allPrerequisites + courseId)
    },

    search: async (programmeType, courseCode) => {
        return axios.get(Links.course.search + "?programme_type=" + programmeType + "&query=" + courseCode)
    },

    addPrerequisite: async (courseId, data) =>{
        return axios.put(Links.course.addPrerequisite + courseId, data)
    },

    removePrerequisite: async (courseId, data) =>{
        return axios.put(Links.course.removePrerequisite + courseId, data)
    },

    uploadCourses: async (data) => {
        return axios.post(Links.course.uploadCourses, data, {
            headers: { 'Content-Type': 'multipart/form-data' },
        })
    },

    deleteAllRegistrations(semester, programme, course, removeResult){
        return axios.delete(Links.course.deregisterAll + "?programmeId=" + programme + "&semesterId=" + semester + "&courseId=" + course + "&removeResult=" + removeResult)
    }
}

export default Course
