import axios from "axios";
import Links from "../constants/Links";

const Lecturer = {
    /**
     * @description Creates a staff
     * @param data an object conatining the following: titleId, userId, firstName, lastName, middleName(optional), email,
     * departmentId(Optional. only is as academic staff is selected), academic(true or false)
     * departments(array of IDs of the departments the user will manage), empty list can be provided,
     * faculties(array of IDs of the faculties the user will manage), empty list can be provided
     * roles(arrays of values of roles)
     * @returns the newly created staff
     */

    create: async (data) => {
        return axios.post(Links.lecturers.create, data)
    },

    makeFacOfficer: async (staffId) => {
        return axios.put(Links.lecturers.makeFacOfficer + staffId)
    },

    makeDepOfficer: async (staffId, data) => {
        return axios.put(Links.lecturers.makeDepOfficer + staffId, data)
    },

    makeFacDean: async (staffId) => {
        return axios.put(Links.lecturers.makeFacDean + staffId)
    },

    /**
     * @description Updates a staff
     * @param data an object conatining the following: titleId, userId, firstName, lastName, middleName(optional), email,
     * departmentId(Optional. only is as academic staff is selected), academic(true or false),
     * * departments(array of IDs of the departments the user will manage), empty list can be provided,
     * faculties(array of IDs of the faculties the user will manage), empty list can be provided
     * roles(arrays of values of roles)
     * @returns the updated staff
     */
    updateFromAdmin: async (id, data) => {
        return axios.put(Links.lecturers.updateFromAdmin + '/' + id, data);
    },

    update: async (data) => {
        return axios.put(Links.lecturers.update, data);
    },

    all: async () => {
        return axios.get(Links.lecturers.all)
    },

    allPaginated: async (page, size, departmentId) => {
        let url = Links.lecturers.allPaginated + `?page=${page}&size=${size}`;
        if(departmentId !== null){
            url = url + `&departmentId=${departmentId}`;
        }
        return axios.get(url)
    },

    allFacultyLecturers: async (id) => {
        return axios.get(Links.lecturers.allFaculty + id)
    },

    allDepartmentLecturers: async (id) => {
        return axios.get(Links.lecturers.allDepartment + id)
    },

    allWithOfficers: async () => {
        return axios.get(Links.lecturers.allWithOfficers)
    },

    getCourses: async (programmeType, staffId) => {
        return axios.get(Links.lecturers.getCourses + programmeType + '/' + staffId)
    },

    getCoursesWithApprovalStatus: async(programmeType, sessionId, semester, staffId) => {
        return axios.get(Links.lecturers.getCoursesWithApprovalStatus + programmeType + '/' + sessionId + '/' + semester + '/' + staffId)
    },

    makeHod: async (staffId) => {
        return axios.put(Links.lecturers.makeHod + staffId)
    },

    unmakeHod: async (staffId) => {
        return axios.put(Links.lecturers.unmakeHod + staffId)
    },

    allSenates: async () => {
        return axios.get(Links.lecturers.allSenates)
    },

    makeSenate: async (staffId) => {
        return axios.put(Links.lecturers.makeSenate + staffId)
    },

    unmakeSenate: async (staffId) => {
        return axios.put(Links.lecturers.unmakeSenate + staffId)
    },

    makeVc: async (staffId) => {
        return axios.put(Links.lecturers.makeVc + staffId)
    },

    makeDVCAcademics: async (staffId) => {
        return axios.put(Links.lecturers.makeDVCAcademics + staffId)
    },

    makeDVCAdministration: async (staffId) => {
        return axios.put(Links.lecturers.makeDVCAdministration + staffId)
    },

    makeBursar: async (staffId) => {
        return axios.put(Links.lecturers.makeBursar + staffId)
    },

    makeRegisterer: async (staffId) => {
        return axios.put(Links.lecturers.makeRegisterer + staffId)
    },

    makeAcademicSecretary: async (staffId) => {
        return axios.put(Links.lecturers.makeAcademicSecretary + staffId)
    },

    /**
     * @description Makes a staff an hostel officer
     * @param staffId The staff ID of ther staff to be made an hostel officer
     * @returns the modified instance of the staff
     */
    makeHostelOfficer: async (staffId) => {
        return axios.put(Links.lecturers.makeHostelOfficer + '/' + staffId)
    },

    /**
     * @description Makes a staff an student affair officer
     * @param staffId The staff ID of ther staff to be made an student affair officer
     * @returns the modified instance of the staff
     */
    makeStudentAffairOfficer: async (staffId) => {
        return axios.put(Links.lecturers.makeStudentAffairOfficer + '/' + staffId)
    },

    /**
     * @description Makes a staff an admission officer
     * @param staffId The staff ID of ther staff to be made an admission officer
     * @returns the modified instance of the staff
     */
    makeAdmissionOfficer: async (staffId) => {
        return axios.put(Links.lecturers.makeAdmissionOfficer + '/' + staffId)
    },

    /**
     * @description Makes a staff a biometric officer
     * @param staffId The staff ID of ther staff to be made a biometric officer
     * @returns the modified instance of the staff
     */
     makeBiometricOfficer: async (staffId) => {
        return axios.put(Links.lecturers.makeBiometricOfficer + '/' + staffId)
    },

    /**
     * @description Removes a staff from list of biometric officers
     * @param staffId The staff ID of ther staff to be removed from biometric officers
     * @returns the modified instance of the staff
     */
     removeBiometricOfficer: async (staffId) => {
        return axios.put(Links.lecturers.removeBiometricOfficer + '/' + staffId)
    },

    /**
     * @description searches a staff by name or userID
     * @param query name or ID
     * @returns {Promise<void>}
     */
    search: async(query) => {
        return axios.get(Links.lecturers.search + "?query=" + query)
    }
}

export default Lecturer
