// user state
import LoginUtil from "../../../login/LoginUtil";
import Api from "@/main/api";

const state = {
    user: null,
    information: null,
    ssceDetails: [],
    scratchCards: [],
    utmeDetails: null,
    nyscDetails: null,
    qualificationCertificate: [],
    applicationFee: null,
    screeningFee: null,
    admissionFee: null,
    super: false,
}

const actions = {
    async getInitialData() {
        return Api.applicants.getInitialData()
    },

    async fetchProgrammeTypes(){

    },

    logout: () => {
        LoginUtil.resetAccessToken()
        LoginUtil.resetRefreshToken()

    }
}

const getters = {
    getUser: state => state.user,
    getSuper: state => state.super,
    getInformation: state => state.information,
    getSSCEDetails: state => state.ssceDetails,
    getScratchCards: state => state.scratchCards,
    getUTMEDetails: state => state.utmeDetails,
    getNYSCDetails: state => state.nyscDetails,
    getQualificationCertificate: state => state.qualificationCertificate,
    getApplicantFee: state => state.applicationFee,
    getScreeningFee: state => state.screeningFee,
    admissionFee: state => state.admissionFee
}

const mutations = {
    getUser: (state, user) => (state), //added this becaus of error from the console
    setCurrentUser: (state, data) => {
        if(typeof data.superOption !== "undefined") {
            state.super = data.superOption
            state.user = data.user
        } else{
            state.user = data
        }
    },
    setInformation: (state, information) => (state.information = information),
    setSSCEDetails: (state, ssceDetails) => (state.ssceDetails = ssceDetails),
    setScratchCards: (state, scratchCards) => (state.scratchCards = scratchCards),
    setNYSCDetails: (state, nyscDetails) => (state.nyscDetails = nyscDetails),
    setQualificationDetails: (state, qualificationCertificate) => (state.qualificationCertificate = qualificationCertificate),
    setUTMEDetails: (state, utmeDetails) => (state.utmeDetails = utmeDetails),
    setApplicationFee: (state, applicationFee) => (state.applicationFee = applicationFee),
    setAdmissionFee: (state, admissionFee) => (state.admissionFee = admissionFee),
    setScreeningFee: (state, screeningFee) => (state.screeningFee = screeningFee),
}

export default {
    state,
    actions,
    getters,
    mutations
}
