import axios from "axios";
import Links from "../constants/Links";

const Schools =  {
    /**
     * @description Gets all schools
     * @returns 
     */
    all: async() => {
        return axios.get(Links.schools.all)
    },

    /**
     * @description creates a new school
     * @param data an object containing the following proporties: name, shortName, dominant(true or false)
     * @returns the newly created object
     */
    create: async (data) => {
        return axios.post(Links.schools.create, data)
    },

    /**
     * @description updates an existing school
     * @param id the id of the school to update
     * @param data an object containing the following proporties: name, shortName, dominant(true or false)
     * @returns the newly created object
     */
    update: async (id, data) => {
        return axios.put(Links.schools.update + '/' + id, data)
    },
    

    /**
     * @description gets the dominant school
     * @returns 
     */
    getDominant: async () => {
        return axios.get(Links.schools.getDominant)
    }
}

export default Schools