import axios from "axios";
import Links from "@/shared/constants/Links";

const Payments = {
    processInline: async(invoice) => {
        return axios.post(Links.payments.processInlinePayment, {reference: invoice})
    },

    initializeStandalone: async(userId, standalonePaymentId) => {
        return axios.post(Links.payments.initializeStandalone, {
            standalonePayment: standalonePaymentId,
            userId: userId
        })
    },

    downloadStandaloneInvoice: async(paymentId) => {
        return axios.get(Links.payments.downloadStandaloneInvoice + "?payment=" + paymentId, {
            responseType: "blob"
        })
    },

    waiveInvoice: async(invoice) => {
        return axios.post(Links.payments.waiveInvoice, {reference: invoice})
    },

    searchInvoice: async(invoice) => {
        return axios.get(Links.payments.searchInvoice + "?invoice=" + invoice)
    },

    /**
     * @description Retrieves all students invoices
     * @param studentId
     * @param payment
     * @returns {Promise<AxiosResponse<any>>}
     */
    invoicesForStudent: async (studentId, payment) => {
        return axios.get(Links.payments.invoiceForStudent + `?student=${studentId}&payment=${payment}`)
    },


    /**
     *
     * @param invoice
     * @param force
     * @returns {Promise<AxiosResponse<any>>}
     */
    deleteInvoice: async (invoice, force) => {
        return axios.post(Links.payments.deleteInvoice, {
            invoice: invoice,
            force: force
        })
    },

    /**
     * @description Moves standalone fee payment to another session
     * @param invoice
     * @param sessionId
     * @returns {Promise<AxiosResponse<any>>}
     */
    moveStandaloneInvoice: async(invoice, sessionId) => {
        return axios.post(Links.payments.moveStandaloneInvoice, {
            invoice: invoice,
            session: sessionId
        })
    }
}

export default Payments;
