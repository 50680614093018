import axios from "axios";
import Links from "../constants/Links";

const System = {
    /**
     * @description Gets settings data for the given programme type
     * @including - levels
     * @param programmeTypeId the id of the programme type to get settings for
     * @returns
     */
    all: async (programmeTypeId) => {
        return axios.get(Links.settings.all + '?programme_type=' + programmeTypeId);
    },

    /**
     * @description Resturns all roles exept for admin, student and applicant
     * @returns List of roles
     */
    getRoles: async () => {
        return axios.get(Links.settings.getRoles)
    },

    /**
     * @description Returns all statuses for students/applicant
     * @returns List of statuses
     */
    getStatuses: async () => {
        return axios.get(Links.settings.getStatuses)
    },

    /**
     * @description Returns all payment types
     * @returns Promise<AxiosResponse<>> of payment types
     */
    getPaymentTypes: async () => {
        return axios.get(Links.settings.getPaymentTypes)
    },

    /**
     * @description Initialize new settings for a programme types
     * @param data object containg id of the programme type to initialize new settings for.
     *  property is: programmeTypeId
     * @returns the new settings
     */
    newSetting: async (data) => {
        return axios.post(Links.settings.newSetting, data)
    },


    /**
     * @description Updates level with given id
     * @param levelId the id of the level to update
     * @param data the object containing the properties to update
     * @returns the updated level
     */
    updateLevel: async (levelId, data) => {
        return axios.put(Links.settings.updateLevel + '/' + levelId, data);
    },

    /**
     * @description Creates a new level
     * @param data the object containing the the following propties:
     * title, order and programmeTypeId
     * @returns the newly created level
     */
    createLevel: async (data) => {
        return axios.post(Links.settings.createLevel, data);
    },

    /**
     * @description Updates settings
     * @param id ID of the settings to update
     * @param data object containning the new propeties to update settings with. these propeties includes: passMark, probationCGPA, withdrawalCGPA and gradingSystem
     * @returns the enewly updated settings
     */
    updateSettings: async (id, data) => {
        return axios.post(Links.settings.updateSettings + '/' + id, data);
    },

    /**
     * @description Creates a new grade
     * @param data the object containing the the following propties:
     * title, gradeOrder(number), gradeLimit(number), creditValue(number) and programmeTypeId(number)
     * @returns the newly created grade
     */
     createGrade: async (data) => {
        return axios.post(Links.settings.createGrade, data);
    },

    /**
     * @description Updates and existing grade
     * @param gradeId the id of the grade to update
     * @param data object containing the new properties to update
     * the grade with
     * @returns the updated grade
     */

    updateGrades: async (gradeId, data) => {
        return axios.put(Links.settings.updateGrade + '/' + gradeId, data);
    },

    /**
     * @description Gets all mode of entreis for a programme type
     * @param programmeTypeId the programme ID to get mode of entries for
     * @returns
     */
    modeOfEntries: async (programmeTypeId) => {
        return axios
            .get(Links.settings.modeOfEntries + '?programme_type=' + programmeTypeId);
    },

    /**
     * @description Creates a new mode of entry
     * @param data an object containing the following properties:
     * title(string), value(string), levelId(Level Id), requireUtmeScores(checkbox true or false),
     * numberOfSemesters, programmeTypeId(Programme type id)
     * @returns the newly created mode of entry
     */
    createModeOfEntry: async (data) => {
        return axios.post(Links.settings.createModeOfEntry, data)
    },

    /**
     * @description Updates an existing mode of entry
     * @id ID of the mode of entry to update
     * @param data an object containing the following properties:
     * title(string), value(string), levelId(Level Id), requireUtmeScores(checkbox true or false), programmeTypeId(Programme type id)
     * @returns the newly created mode of entry
     */
     updateModeOfEntry: async (id, data) => {
        return axios.put(Links.settings.updateModeOfEntry + '/' + id, data)
    },

    updateIndigenousState: async (data) => {
        return axios.post(Links.settings.updateIndigenousState, data)
    },

    /**
     * @description Updates classes of degrees
     * @param id of the class of degree to update
     * @param data the object containing the following
     *  properties:
     * firstClass, secondClassUpper, secondClassLower, thirdClass and pass
     * @returns the updated class of degree
     */
    updateClassOfDegree: async (id, data) => {
        return axios.put(Links.settings.updateClassOfDegree + '/' + id, data);
    },

    /**
     * @description Get levels for the given programme types
     * @param programmeTypeId
     * @returns levels
     */
    getLevels: async (programmeTypeId) => {
        return axios.get(Links.settings.getLevels + '?programme_type=' + programmeTypeId)
    },

    updateMerchantConfig: async (config) => {
        return axios.put(Links.settings.updateMerchantConfig, config)
    },

    getMerchantConfig: async () => {
        return axios.get(Links.settings.getMerchantConfig)
    }
}

export default System
