import axios from "axios";
import Links from "../constants/Links";

const Auth = {
    login: async (data) => {
        return axios.post(Links.auth.login, data)
    },

    bioAuth: async (data) => {
        return axios.post(Links.auth.bioAuth, data)
    },

    updatePassword: async (data) => {
        return axios.put(Links.auth.updatePassword, data)
    },

    enableMfa: async (data) => {
        return axios.put(Links.auth.enableMfa, data)
    },

    disableMfa: async (data) => {
        return axios.put(Links.auth.disableMfa, data)
    },

    requestCode: async (data) => {
        return axios.put(Links.auth.requestCode, data)
    },

    verifyMfa: async (data) => {
        return axios.put(Links.auth.verifyMfa, data)
    },

    confirmEnableMFA: async (data) => {
        return axios.put(Links.auth.confirmEnableMFA, data)
    },

    unlock: async (data) => {
        return axios.post(Links.auth.unlock, data)
    },

    updateLock: async (data) => {
        return axios.post(Links.auth.updateLock, data)
    },

    sendOtp: async (data) => {
        return axios.post(Links.auth.sendOtp, data)
    },

    verifyOtp: async (data) => {
        return axios.post(Links.auth.verifyOtp, data)
    },

    updatePasswordForgot: async (data) => {
        return axios.post(Links.auth.updatePasswordForgot, data)
    },

    switchUser: async (data) => {
        return axios.post(Links.auth.switchUser, data)
    },

    revertSwitchUser: async (data) => {
        return axios.post(Links.auth.revertSwitchUser, data)
    }
}

export default Auth
