<template>
    <v-card-text tabindex="-1" aria-label="x-card-text">
        <slot />
    </v-card-text>
</template>


<script>
/**
 * import this with the xcard 
 */
export default {
    name: "XCard", 
    props: {}, 
}
</script>