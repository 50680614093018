import axios from "axios";
import Links from "shared/constants/Links";


/**
 * @description - external
 * @returns 
 */

const External = {
    create: async (data) => {
        return axios.post(Links.external.create, data)
    }, 

    update: async (id, data) => {
        return axios.put(Links.external.update + '/' + id, data)
    }, 

    delete: async (id) => {
        return axios.delete(Links.external.delete + '/' + id)
    }, 

    all: async (page, size) => {
        return axios.get(Links.external.all + '?page=' + page + '&size=' + size)
    }
}; 


export default External; 