<template>
    <div >
        <v-sheet elevation="0" outlined rounded v-if="student != null">
            <v-img class="d-flex mx-auto my-2" width="100" height="100" contain :src="image" style="border-radius: 10px; border: #c49a28 2px solid"/>
            <v-row class="my-1 mx-3 mx-md-0">
                <v-col cols="12" md="8" class="d-flex mx-auto">
                    <div class="font-weight-medium">Name:</div>

                    <h4 class="d-flex ml-auto font-weight-light justify-end">{{ student.name }}</h4>
                </v-col>



                <v-col cols="12" md="8" class="d-flex mx-auto">
                    <div class="font-weight-medium">Matric:</div>
                    <h4 class="d-flex ml-auto font-weight-light justify-end">{{ student.userId }}</h4>
                </v-col>


                <v-col cols="12" md="8" class="d-flex mx-auto">
                    <div class="font-weight-medium">Level:</div>
                    <h4 class="d-flex ml-auto font-weight-light">{{ student.level.title }}</h4>
                </v-col>

                <v-col cols="12" md="8" class="d-flex mx-auto">
                    <div class="font-weight-medium">Year of Admission:</div>
                    <h4 class="d-flex ml-auto font-weight-light">{{ student.yearOfAdmission.name }}</h4>
                </v-col>

                <v-col cols="12" md="8" class="d-flex mx-auto">
                    <div class="font-weight-medium">Programme Type:</div>
                    <h4 class="d-flex font-weight-light ml-auto">{{ student.programme.programmeType.name }}</h4>
                </v-col>

                <v-col cols="12" md="8" class="d-flex mx-auto">
                    <div class="font-weight-medium">Faculty:</div>
                    <h4 class="d-flex font-weight-light ml-auto">{{ student.programme.department.faculty.name }}</h4>
                </v-col>


                <v-col cols="12" md="8" class="d-flex mx-auto">
                    <div class="font-weight-medium">Department:</div>
                    <h4 class="d-flex font-weight-light ml-auto">{{ student.programme.department.name }}</h4>
                </v-col>
            </v-row>
        </v-sheet>

    </div>
</template>


<script>
import LoadingDialog from "@ui/components/LoadingDialog";
import MessageBox from "@ui/components/MessageBox";
import Defaults from 'shared/Defaults';
import { mapGetters } from "vuex";

export default {
    name: "Profile",
    components: {
        MessageBox,
        LoadingDialog
    },
    data: () => ({
        messageType: '',
        message: '',
        messageShow: false,
        loading: false,
        image: '',
        student: null,
    }),

    watch: {
        getUser: function(val){
            this.student = val;
            if(val != null){
                this.image = Defaults.api + '/profile-picture/fetch?user=' + val.userId;
            }
        }
    },

    computed: {
        ...mapGetters(['getUser']),
        isMobile: function() {
            return this.$vuetify.breakpoint.xs;
        }
    },

    created() {
        if(this.getUser != null){
            this.student = this.getUser;
            this.image = Defaults.api + '/profile-picture/fetch?user=' + this.getUser.userId;
        }
    }
}
</script>
