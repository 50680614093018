import axios from "axios";
import Links from "@/shared/constants/Links";

const ScratchCard = {
    getConfig: async (organization) => {
        return axios.get(Links.scratchCards.getConfig + '?organization=' + organization)
    },

    updateConfig: async (id, data) => {
        return axios.put(Links.scratchCards.updateConfig + '/' + id, data)
    },

    initializeTransaction: async (data) => {
        return axios.post(Links.scratchCards.initializeTransaction, data)
    },

    verifyTransaction: async (reference) => {
        return axios.put(Links.scratchCards.verifyTransaction + '/' + reference)
    },

    upload: async (data) => {
        return axios.post(Links.scratchCards.upload, data, {
            headers: { 'Content-Type': 'multipart/form-data' },
        })
    },

    fetch: async (org, type, page, size) => {
        return axios.get(Links.scratchCards.fetch + '?org=' + org + '&type=' + type + '&page=' + page + '&size=' + size)
    },

    updateNew: async (data) => {
        return axios.post(Links.scratchCards.updateNew, data, {
            headers: { 'Content-Type': 'multipart/form-data' },
        })
    }
}

export default ScratchCard