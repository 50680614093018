<template >
 
  <v-app>
      <v-main v-if="!loading && !error">
        <transition name="fade">
          <router-view ></router-view>
        </transition>
        <toasts />
        
        <progress-bar />
      </v-main>

      <div class="errorboundary mx-auto" v-else> 
        <h2>Sorry An Error was Detected...</h2>

        <div class="btns d-flex justify-center mx-auto mt-8">
          <v-btn  depressed large color="transcriptPrimary" outlined @click="$router.go(0)">Refresh </v-btn>
          
          <!-- Please enter the required support unit url -->
          <v-btn class="ml-5" large depressed color="error" target="_blank" outlined href="https://github.com/iamgabrielsoft" link>Report</v-btn>
        </div>

         <footer>&copy; Nasarawa State University, Transcript {{ new Date().getFullYear() }}</footer>
      </div>
  </v-app>
</template>

<script>
import { provide, inject, ref, computed, watch } from "vue";  
import { mapActions  } from "vuex"; 
import Api from '@/main/api';
import ErrorResolver from '@/shared/ErrorResolver';
import ProgressBar from "./components/ProgressBar.vue"; 
import Loading from "./components/Loading.vue"; 
import Toasts from "./components/Toast/Toasts.vue";
import store from 'application/store';

export default {
  name: 'App',
  data: () => ({
    error: null, 
    loading: false,
    user: null, 
    message: '',
    random: [
      "This is where it all begins...",
      "Commit committed...",
      "Version control is awful...",
      "COMMIT ALL THE FILES...",
      "The same thing we do every night, Pinky - try to take over the world!",
      "Lock S-foils in attack position",
      "This commit is a lie",
      "I'll explain when you're older!",
      "Here be Dragons",
      "Reinventing the wheel. Again.",
      "This is not the commit message you are looking for",
      "Batman! (this commit has no parents)",
    ],
  }),

  components: {
    Toasts,
    Loading,
    ProgressBar
  },

  beforeDestroy() {
    return this.error; 
  }, 

  errorCaptured(err) {

    new Promise((resolve, reject) => {
      if(err){
        resolve(err); 
        this.error = err; 
      }

      reject(err); 
      this.error = null; 
    })

    return false; 
  }, 

  methods: {
    ...mapActions(['fetchCurrentUser', 'fetchProgrammeTypes']),

    /** change document title  */
    changeTitle() {
      Api.schools.getDominant().then((res) => {
        this.title = res.data.name;
        document.title = this.title // change the page title 
      }).catch((err) => ErrorResolver.checkErrors(err, err))
    }, 

    /** generate random messages */
    generateMessage() {
      let interval = setInterval(() => {
        this.message = this.random[Math.floor(Math.random() * this.random.length)] 
         clearInterval(interval);
      }, 2000); 
    }
  },


  created() {
    this.changeTitle();
  }, 

  async mounted() {

  }
};
</script>

<style  lang="scss">

p {
  color: black;
}

.v-main {
  overflow-x: hidden;
  scroll-behavior: smooth;
 overflow-y: hidden;
//  opacity: 10; 
//  transition: opacity 500ms;

}

body {
 scroll-behavior: smooth;
}

body::-webkit-scrollbar {
  width: 0.5em;
  border-radius: 50px;
}
 
body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}
 
body::-webkit-scrollbar-thumb {
  height: 0.5em;
  background-color: black;
  outline: 1px solid slategrey;
}

.errorboundary {

  margin-top: 15em;

  h2 {
    color: gray; 
    font-size: 2.8em;
  }

  footer {
      color: gray;
      position: fixed;
      text-align: center;
      right: 0; 
      left: 0; 
      bottom: 0; 
    }
}
</style>