import axios from "axios";
import Links from "../constants/Links";

const Downloads = {
    courseForm: async (student, session, semester) => {
        return axios.get(Links.downloads.courseForm + '?session=' + session + '&semester=' + semester + '&student=' + student, {responseType: 'blob'})
    },

    examCard: async (student, session, semester) => {
        return axios.get(Links.downloads.examCard + '?session=' + session + '&semester=' + semester + '&student=' + student, {responseType: 'blob'})
    },

    offeredCourseAttendance: async (course, session, semester) => {
        return axios.get(Links.downloads.offeredCourseAttendance + '?session=' + session + '&semester=' + semester + '&course=' + course, {responseType: 'arraybuffer'})
    },

    resultReportSheet: async (course, session, semester) => {
        return axios.get(Links.downloads.resultReportSheet + '?session=' + session + '&semester=' + semester + '&course=' + course, {responseType: 'blob'})
    },
}

export default Downloads