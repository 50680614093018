import axios from "axios";
import Links from "../constants/Links";

const ProfilePicture = {
    store: async (data) => {
        return axios.post(Links.profilePicture.store, data)
    },

    retrieve: async (userId) => {
        return axios.get(Links.profilePicture.store + "?user=" + userId)
    }
}

export default ProfilePicture